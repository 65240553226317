<div class="page-title-area page-title-four">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="page-title-item">
                <h2>Women Psychiatry</h2>
                <ul>
                    <li><a routerLink="/">Home</a></li>
                    <li><i class="icofont-simple-right"></i></li>
                    <li>Diseases</li>
                    <li><i class="icofont-simple-right"></i></li>
                    <li>Women Psychiatry</li>
                </ul>
            </div>
        </div>
    </div>
</div>

<section class="departments-area pt-100">
    <div class="container">
        <div class="row">
            <div class="col-sm-6 col-lg-4">
                <div class="department-item">
                    <i class="icofont-patient-bed"></i>
                    <h3>Depression In Pregnancy</h3>
                    <p>Women are more at risk of depression while they are pregnant, and during the weeks and months after having a baby. During pregnancy, hormone changes can affect brain chemicals and cause depression and anxiety. Sometimes, pregnant women
                        don’t realize they are depressed. Many think they have symptoms of pregnancy or the “baby blues,”.</p>
                </div>
            </div>
            <div class="col-sm-6 col-lg-4">
                <div class="department-item">
                    <i class="icofont-patient-bed"></i>
                    <h3>Psychosis In Pregnancy</h3>
                    <p>Postpartum psychosis is a medical emergency. Women who have been diagnosed with bipolar disorder or schizophrenia before their pregnancy have a higher risk or getting it but it can happen to any woman, even if they have not had a mental
                        illness before. Most women make a full recovery with the right treatment. Around 1 in 1,000 women gets postpartum psychosis.</p>
                </div>
            </div>
            <div class="col-sm-6 col-lg-4">
                <div class="department-item">
                    <i class="icofont-patient-bed"></i>
                    <h3>Anxiety In Pregnancy</h3>
                    <p>Some pregnant women feel distressed or guilty about feeling anxious or panicky when everyone expects them to be happy. But anxiety is a mental health condition and not a sign of weakness, something that will go away on its own or that
                        you should just ‘snap out of’. Anxiety in pregnancy is very common. It has been found that more than 1 in 10 pregnant women have it.</p>
                </div>
            </div>
            <div class="col-sm-6 col-lg-4">
                <div class="department-item">
                    <i class="icofont-patient-bed"></i>
                    <h3>Premenstrual Dysphoric Disorder</h3>
                    <p>Premenstrual dysphoric disorder (PMDD) is similar to premenstrual syndrome (PMS) but is more serious. It causes severe irritability, depression, or anxiety in the week or two before your period starts. Symptoms usually go away two
                        to three days after the period starts. It affects up to 5% of women of childbearing age. Many women with PMDD may also have anxiety or depression.</p>
                </div>
            </div>
            <div class="col-sm-6 col-lg-4">
                <div class="department-item">
                    <i class="icofont-patient-bed"></i>
                    <h3>Pre-menopausal Syndrome</h3>
                    <p>Approaching middle age often brings increased stress, anxiety, and fear. This can partially be attributed to physical changes, such as decreasing levels of estrogen and progesterone. Hot flashes, sweating, and other symptoms of menopause
                        may cause disruptions. There may also be emotional changes, such as worries about getting older, losing family members, or children leaving home.</p>
                </div>
            </div>
            <div class="col-sm-6 col-lg-4">
                <div class="department-item">
                    <i class="icofont-patient-bed"></i>
                    <h3>Psychogenic Hiccups</h3>
                    <p>The hiccups in this case are best characterized as a conversion reaction, representing repressed hostility and guilt over sexual conflicts in the patient and her family constellation. Treatment by hypnotic suggestion, directed at symptom
                        removal, is combined with a slight modification of the interaction between the patient and her family. </p>
                </div>
            </div>

        </div>
    </div>
</section>