<div class="page-title-area page-title-four">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="page-title-item">
                <h2>Child &amp; Adolescent Psychiatry</h2>
                <ul>
                    <li><a routerLink="/">Home</a></li>
                    <li><i class="icofont-simple-right"></i></li>
                    <li>Diseases</li>
                    <li><i class="icofont-simple-right"></i></li>
                    <li>Child &amp; Adolescent Psychiatry</li>
                </ul>
            </div>
        </div>
    </div>
</div>

<section class="departments-area pt-100">
    <div class="container">
        <div class="row">
            <div class="col-sm-6 col-lg-4">
                <div class="department-item">
                    <i class="icofont-patient-bed"></i>
                    <h3>ADHD</h3>
                    <p>Attention deficit hyperactivity disorder (ADHD) is a neurodevelopmental disorder characterized by inattention, or excessive activity and impulsivity, which are otherwise not appropriate for a person's age. Some individuals with ADHD
                        also display difficulty regulating emotions or problems with executive function. For a diagnosis, the symptoms should appear before a person is twelve years old.</p>
                </div>
            </div>
            <div class="col-sm-6 col-lg-4">
                <div class="department-item">
                    <i class="icofont-patient-bed"></i>
                    <h3>Autism</h3>
                    <p>Autism (Autistic Spectrum Disorder) is a developmental disorder characterized by difficulties with social interaction and communication, and by restricted and repetitive behavior. Parents often notice signs during the first three years
                        of their child's life. These signs often develop gradually, though some children with autism experience worsening in their communication and social skills.</p>
                </div>
            </div>
            <div class="col-sm-6 col-lg-4">
                <div class="department-item">
                    <i class="icofont-patient-bed"></i>
                    <h3>Conduct Disorder</h3>
                    <p>Conduct disorder (CD) is a mental disorder diagnosed in childhood or adolescence that presents itself through a repetitive and persistent pattern of behavior in which the basic rights of others or major age-appropriate norms are violated.
                        These behaviors are often referred to as "antisocial behaviors." It is often seen as the precursor to antisocial personality disorder.</p>
                </div>
            </div>
            <div class="col-sm-6 col-lg-4">
                <div class="department-item">
                    <i class="icofont-patient-bed"></i>
                    <h3>Borderline Intelligence</h3>
                    <p>Borderline intellectual functioning, also called borderline mental retardation (in the ICD-8), is a categorization of intelligence wherein a child has below average cognitive ability (generally an IQ of 70–85), but the deficit is not
                        as severe as intellectual disability (below 70). This is technically a cognitive impairment; however, this group may not be sufficiently mentally disabled.
                    </p>
                </div>
            </div>
            <div class="col-sm-6 col-lg-4">
                <div class="department-item">
                    <i class="icofont-patient-bed"></i>
                    <h3>Intellectual disability</h3>
                    <p>Intellectual disability (ID), (or general learning disability) formerly mental retardation (MR), is a generalized neurodevelopmental disorder characterized by significantly impaired intellectual and adaptive functioning. It is defined
                        by an IQ under 70, in addition to deficits in two or more adaptive behaviors that affect everyday living. It affects about 2–3% of the general population.</p>
                </div>
            </div>
            <div class="col-sm-6 col-lg-4">
                <div class="department-item">
                    <i class="icofont-patient-bed"></i>
                    <h3>Poor Academic Performance</h3>
                    <p>Poor school performance not only results in the child having a low self-esteem, but also causes significant stress to the parents. Reasons for children to under perform at school are medical problems, below average intelligence, specific
                        learning disability, ADHD, emotional problems, poor socio-cultural home environment.</p>
                </div>
            </div>
            <div class="col-sm-6 col-lg-4">
                <div class="department-item">
                    <i class="icofont-patient-bed"></i>
                    <h3>Gadget Addiction</h3>
                    <p>Teens have a complex relationship with technology. 50 percent of teens "feel addicted" to gadgets, especially mobile devices, while 59 percent of parents surveyed believe that kids are addicted to their devices. There isn’t a recognized
                        "smartphone addiction" diagnosis, it's natural for parents to wonder if a teen's apparent obsession with a smartphone qualifies as addictive behavior.</p>
                </div>
            </div>
            <div class="col-sm-6 col-lg-4">
                <div class="department-item">
                    <i class="icofont-patient-bed"></i>
                    <h3>Childhood OCD</h3>
                    <p>A child with OCD has obsessive thoughts that are not wanted. They are linked to fears, such as touching dirty objects. He or she uses compulsive rituals to control the fears, such as excessive handwashing. As children grow, rituals
                        and obsessive thoughts normally happen with a purpose and focus based on age. Children with OCD don't have enough of a chemical called serotonin in their brain.</p>
                </div>
            </div>
            <div class="col-sm-6 col-lg-4">
                <div class="department-item">
                    <i class="icofont-patient-bed"></i>
                    <h3>Childhood Depression</h3>
                    <p>Childhood depression is different from the normal "blues" and everyday emotions that occur as a child develops. Just because a child seems sad doesn't necessarily mean they have significant depression. If the sadness becomes persistent,
                        or interferes with normal social activities, interests, schoolwork, or family life, it may indicate that they have a depressive illness.</p>
                </div>
            </div>
            <div class="col-sm-6 col-lg-4">
                <div class="department-item">
                    <i class="icofont-patient-bed"></i>
                    <h3>Childhood Anxiety Disorder</h3>
                    <p>Children can feel anxious about different things at different ages. Many of these worries are a normal part of growing up. From the age of around 6 months to 3 years it's very common for young children to have separation anxiety. They
                        may become clingy and cry when separated from their parents. Anxiety becomes a problem for children when it starts to get in the way of their everyday life.</p>
                </div>
            </div>
            <div class="col-sm-6 col-lg-4">
                <div class="department-item">
                    <i class="icofont-patient-bed"></i>
                    <h3>Examination Phobia</h3>
                    <p>the idea of competency is nowadays looked more into as competition. With exams knocking on the door, the pressure of competition is seen to affect many aspirants. Even toppers are seen gaining a sense of fear or anxiety for the upcoming
                        test. Anxiety can come before, during or even after the exam. Many term it as test anxiety, exam phobia or examination fever. Many students feel that they will forget their answers.</p>
                </div>
            </div>
            <div class="col-sm-6 col-lg-4">
                <div class="department-item">
                    <i class="icofont-patient-bed"></i>
                    <h3>Childhood Psychosis</h3>
                    <p>Most children with Psychosis show delays in language and other functions long before their psychotic symptoms (hallucinations, delusions, and disordered thinking) appear. In the first years of life, about 30% of these children have
                        transient symptoms of pervasive developmental disorder, such as rocking, posturing, and arm flapping. Childhood-onset of psychosis may present with poor motor development.</p>
                </div>
            </div>
            <div class="col-sm-6 col-lg-4">
                <div class="department-item">
                    <i class="icofont-patient-bed"></i>
                    <h3>Nail Biting</h3>
                    <p>Around 50% of children between 10 and 18 bite their nails at least occasionally, and for many kids, the habit starts even younger. While some kids bite their nails because they are fidgety, others don't know what else to do when they
                        feel anxious. Fingernail biting can be self-soothing. In addition to being gross to witness, nail-biting could do some damage to your child's teeth and nails.</p>
                </div>
            </div>
            <div class="col-sm-6 col-lg-4">
                <div class="department-item">
                    <i class="icofont-patient-bed"></i>
                    <h3>Hair Pulling</h3>
                    <p>Hair Pulling or Trichotillomania is a disorder characterized by the urge to pull out hair from the scalp or other parts of the body, including the eyelashes, brows, genitals, back, arms and legs. Children are more likely to pull hair
                        out from the scalp. Some with the disorder say pulling hair makes them feel good, but not everyone with trichotillomania pulls out hair intentionally. </p>
                </div>
            </div>
            <div class="col-sm-6 col-lg-4">
                <div class="department-item">
                    <i class="icofont-patient-bed"></i>
                    <h3>Psychogenic Vomiting</h3>
                    <p>Psychogenic vomiting has been defined as persistent regurgitation of previously swallowed food in the absence of organic pathology. The presence of this disorder in childhood, particularly among infants, may have severe physical consequences
                        including dehydration, malnutrition and lowered resistance to disease if significant amounts of food are lost repeatedly. It does not appear to be a benign condition</p>
                </div>
            </div>
            <div class="col-sm-6 col-lg-4">
                <div class="department-item">
                    <i class="icofont-patient-bed"></i>
                    <h3>Eating Disorder</h3>
                    <p>Eating disorder is defined by abnormal eating habits that negatively affect a child's or adolescent's physical and/or mental health. It includes binge eating disorder (eating a large amount in a short period of time), anorexia nervosa,
                        (eating very little due to a fear of gaining weight), bulimia nervosa, (eating a lot and then try to rid themselves of the food), pica (eating non-food items).
                    </p>
                </div>
            </div>
            <div class="col-sm-6 col-lg-4">
                <div class="department-item">
                    <i class="icofont-patient-bed"></i>
                    <h3>Conversion Disorder</h3>
                    <p>Conversion disorder (CD), or functional neurologic symptom disorder, is a diagnostic category used in some psychiatric classification systems. It is sometimes applied to patients who present with neurological symptoms, such as numbness,
                        blindness, paralysis, or fits, which are not consistent with a well-established organic cause, which cause significant distress.</p>
                </div>
            </div>
            <div class="col-sm-6 col-lg-4">
                <div class="department-item">
                    <i class="icofont-patient-bed"></i>
                    <h3>Teenage Behavioural Problem</h3>
                    <p>Problematic externalizing behaviors, including lying, stealing, vandalism, truancy, arson, promiscuity, defiance toward authority, disinhibition (severe impulsivity), and aggression (threatening, bullying, fighting, rape), are some
                        of the symptoms. The disruptive behavior is problematic for those outside the family too.</p>
                </div>
            </div>

        </div>
    </div>
</section>