<div class="home-slider owl-theme owl-carousel">
    <div class="slider-item slider-item-img">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="slider-text">
                        <div class="slider-shape">
                            <img src="assets/img/home-one/home-slider/1.png" alt="Shape">
                        </div>
                        <h1>Exceptional Health Care For all</h1>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="slider-item slider-item-img">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="slider-text">
                        <div class="slider-shape">
                            <img src="assets/img/home-one/home-slider/2.png" alt="Shape">
                        </div>
                        <h1>No Life Without A Healthy Mind</h1>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="slider-item slider-item-img">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="slider-text">
                        <div class="slider-shape">
                            <img src="assets/img/home-one/home-slider/3.png" alt="Shape">
                        </div>
                        <h1>We Provide Quality Treatments</h1>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="counter-area">
    <div class="container">
        <div class="row counter-bg">
            <div class="col-sm-6 col-md-3 col-lg-3">
                <div class="counter-item">
                    <i class="icofont-patient-bed"></i>
                    <h3><span class="counter">17</span>+</h3>
                    <p>Years of Experience</p>
                </div>
            </div>

            <div class="col-sm-6 col-md-3 col-lg-3">
                <div class="counter-item">
                    <i class="icofont-people"></i>
                    <h3><span class="counter">40000</span>+</h3>
                    <p>Happy Patients</p>
                </div>
            </div>

            <div class="col-sm-6 col-md-3 col-lg-3">
                <div class="counter-item">
                    <i class="icofont-doctor-alt"></i>
                    <h3><span class="counter">200</span>+</h3>
                    <p>Conferences</p>
                </div>
            </div>

            <div class="col-sm-6 col-md-3 col-lg-3">
                <div class="counter-item">
                    <i class="icofont-badge"></i>
                    <h3><span class="counter">25</span>+</h3>
                    <p>Awards &amp; Recognitions</p>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="about-area pt-100 pb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="about-item">
                    <div class="about-left">
                        <img src="assets/img/home-one/home-bg4.jpg" alt="About">
                    </div>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="about-item about-right">
                    <img src="assets/img/home-one/home-bg5.png" alt="About">
                    <h2>About Our Clinic</h2>
                    <p>Dr. Pawan Kr Barnwal is a renowned Consultant Neuropsychiatrist &amp; Behaviour Science Specialist in Ranchi. His clinic, established in 2003, acts as a one-stop destination serving patients from around the globe. Over the course of
                        our journey, we have established a firm foothold in the field of Neuropsychiatry and allied branches like Drug De-addiction and sexual problems. The belief that patient satisfaction is as important, has helped us garner a vast
                        base of patients, which continues to grow by the day.
                    </p>

                    <ul>
                        <li><i class="icofont-check-circled"></i> Accurate Diagnosis</li>
                        <li><i class="icofont-check-circled"></i> Guaranteed Treatment</li>
                        <li><i class="icofont-check-circled"></i> Patient Satisfaction</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="services-area pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Our Services</h2>
        </div>

        <div class="row">
            <div class="col-sm-6 col-lg-3 wow fadeInUp" data-wow-delay=".3s">
                <div class="service-item">
                    <div class="service-front">
                        <i class="icofont-doctor"></i>
                        <h3>Neuropsychiatry</h3>
                    </div>

                    <div class="service-end">
                        <i class="icofont-doctor"></i>
                        <h3>Neuropsychiatry</h3>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-lg-3 wow fadeInUp" data-wow-delay=".5s">
                <div class="service-item">
                    <div class="service-front">
                        <i class="icofont-doctor"></i>
                        <h3>Drug De-addiction</h3>
                    </div>

                    <div class="service-end">
                        <i class="icofont-doctor"></i>
                        <h3>Drug De-addiction</h3>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3 wow fadeInUp" data-wow-delay=".7s">
                <div class="service-item">
                    <div class="service-front">
                        <i class="icofont-doctor"></i>
                        <h3>Psychological Counselling in Children</h3>
                    </div>

                    <div class="service-end">
                        <i class="icofont-doctor"></i>
                        <h3>Psychological Counselling in Children</h3>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3 wow fadeInUp" data-wow-delay=".9s">
                <div class="service-item">
                    <div class="service-front">
                        <i class="icofont-doctor"></i>
                        <h3>Child Psychiatry</h3>
                    </div>

                    <div class="service-end">
                        <i class="icofont-doctor"></i>
                        <h3>Child Psychiatry</h3>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3 wow fadeInUp" data-wow-delay=".3s">
                <div class="service-item">
                    <div class="service-front">
                        <i class="icofont-doctor"></i>
                        <h3>Adult Psychiatry</h3>
                    </div>

                    <div class="service-end">
                        <i class="icofont-doctor"></i>
                        <h3>Adult Psychiatry</h3>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3 wow fadeInUp" data-wow-delay=".5s">
                <div class="service-item">
                    <div class="service-front">
                        <i class="icofont-doctor"></i>
                        <h3>Women Psychiatry</h3>
                    </div>

                    <div class="service-end">
                        <i class="icofont-doctor"></i>
                        <h3>Women Psychiatry</h3>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3 wow fadeInUp" data-wow-delay=".7s">
                <div class="service-item">
                    <div class="service-front">
                        <i class="icofont-doctor"></i>
                        <h3>Geriatric (Old Age) Psychiatry</h3>
                    </div>

                    <div class="service-end">
                        <i class="icofont-doctor"></i>
                        <h3>Geriatric (Old Age) Psychiatry</h3>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3 wow fadeInUp" data-wow-delay=".9s">
                <div class="service-item">
                    <div class="service-front">
                        <i class="icofont-doctor"></i>
                        <h3>Psychological Assessment</h3>
                    </div>

                    <div class="service-end">
                        <i class="icofont-doctor"></i>
                        <h3>Psychological Assessment</h3>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3 wow fadeInUp" data-wow-delay=".3s">
                <div class="service-item">
                    <div class="service-front">
                        <i class="icofont-doctor"></i>
                        <h3>Stress Management</h3>
                    </div>

                    <div class="service-end">
                        <i class="icofont-doctor"></i>
                        <h3>Stress Management</h3>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3 wow fadeInUp" data-wow-delay=".5s">
                <div class="service-item">
                    <div class="service-front">
                        <i class="icofont-doctor"></i>
                        <h3>Suicide Prevention Counselling</h3>
                    </div>

                    <div class="service-end">
                        <i class="icofont-doctor"></i>
                        <h3>Suicide Prevention Counselling</h3>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3 wow fadeInUp" data-wow-delay=".7s">
                <div class="service-item">
                    <div class="service-front">
                        <i class="icofont-doctor"></i>
                        <h3>Couple Counselling</h3>
                    </div>

                    <div class="service-end">
                        <i class="icofont-doctor"></i>
                        <h3>Couple Counselling</h3>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3 wow fadeInUp" data-wow-delay=".9s">
                <div class="service-item">
                    <div class="service-front">
                        <i class="icofont-doctor"></i>
                        <h3>Premarital Counselling</h3>
                    </div>

                    <div class="service-end">
                        <i class="icofont-doctor"></i>
                        <h3>Premarital Counselling</h3>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3 wow fadeInUp" data-wow-delay=".3s">
                <div class="service-item">
                    <div class="service-front">
                        <i class="icofont-doctor"></i>
                        <h3>Marital Counselling</h3>
                    </div>

                    <div class="service-end">
                        <i class="icofont-doctor"></i>
                        <h3>Marital Counselling</h3>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3 wow fadeInUp" data-wow-delay=".5s">
                <div class="service-item">
                    <div class="service-front">
                        <i class="icofont-doctor"></i>
                        <h3>Extra Marital Counselling</h3>
                    </div>

                    <div class="service-end">
                        <i class="icofont-doctor"></i>
                        <h3>Extra Marital Counselling</h3>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3 wow fadeInUp" data-wow-delay=".7s">
                <div class="service-item">
                    <div class="service-front">
                        <i class="icofont-doctor"></i>
                        <h3>Behaviour Therapy</h3>
                    </div>

                    <div class="service-end">
                        <i class="icofont-doctor"></i>
                        <h3>Behaviour Therapy</h3>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3 wow fadeInUp" data-wow-delay=".9s">
                <div class="service-item">
                    <div class="service-front">
                        <i class="icofont-doctor"></i>
                        <h3>Psychotherapy</h3>
                    </div>

                    <div class="service-end">
                        <i class="icofont-doctor"></i>
                        <h3>Psychotherapy</h3>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3 wow fadeInUp" data-wow-delay=".3s">
                <div class="service-item">
                    <div class="service-front">
                        <i class="icofont-doctor"></i>
                        <h3>Psycho Sexual Behaviour Therapy</h3>
                    </div>

                    <div class="service-end">
                        <i class="icofont-doctor"></i>
                        <h3>Psycho Sexual Behaviour Therapy</h3>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3 wow fadeInUp" data-wow-delay=".5s">
                <div class="service-item">
                    <div class="service-front">
                        <i class="icofont-doctor"></i>
                        <h3>Sexual Deviant Behaviour Therapy</h3>
                    </div>

                    <div class="service-end">
                        <i class="icofont-doctor"></i>
                        <h3>Sexual Deviant Behaviour Therapy</h3>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3 wow fadeInUp" data-wow-delay=".7s">
                <div class="service-item">
                    <div class="service-front">
                        <i class="icofont-doctor"></i>
                        <h3>Memory Test</h3>
                    </div>

                    <div class="service-end">
                        <i class="icofont-doctor"></i>
                        <h3>Memory Test</h3>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3 wow fadeInUp" data-wow-delay=".9s">
                <div class="service-item">
                    <div class="service-front">
                        <i class="icofont-doctor"></i>
                        <h3>Psychological Test</h3>
                    </div>

                    <div class="service-end">
                        <i class="icofont-doctor"></i>
                        <h3>Psychological Test</h3>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3 wow fadeInUp" data-wow-delay=".3s">
                <div class="service-item">
                    <div class="service-front">
                        <i class="icofont-doctor"></i>
                        <h3>IQ Test</h3>
                    </div>

                    <div class="service-end">
                        <i class="icofont-doctor"></i>
                        <h3>IQ Test</h3>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-3 wow fadeInUp" data-wow-delay=".5s">
                <div class="service-item">
                    <div class="service-front">
                        <i class="icofont-doctor"></i>
                        <h3>EEG</h3>
                    </div>

                    <div class="service-end">
                        <i class="icofont-doctor"></i>
                        <h3>EEG</h3>
                    </div>
                </div>
            </div>

        </div>
    </div>
</section>

<section class="expertise-area pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Our Expertise</h2>
        </div>

        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="expertise-item">
                    <div class="row">
                        <div class="col-sm-6 col-lg-6 wow fadeInUp" data-wow-delay=".3s">
                            <div class="expertise-inner">
                                <i class="icofont-doctor-alt"></i>
                                <h3>Eminent Neuropsychiatrist</h3>
                            </div>
                        </div>

                        <div class="col-sm-6 col-lg-6 wow fadeInUp" data-wow-delay=".5s">
                            <div class="expertise-inner">
                                <i class="icofont-beaker"></i>
                                <h3>Wholistic De-addiction</h3>
                            </div>
                        </div>

                        <div class="col-sm-6 col-lg-6 wow fadeInUp" data-wow-delay=".3s">
                            <div class="expertise-inner">
                                <i class="icofont-nurse"></i>
                                <h3>Experienced Sexologist</h3>
                            </div>
                        </div>

                        <div class="col-sm-6 col-lg-6 wow fadeInUp" data-wow-delay=".5s">
                            <div class="expertise-inner">
                                <i class="icofont-holding-hands"></i>
                                <h3>Counselling &amp; Psychotherapy</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="expertise-item">
                    <div class="expertise-right">
                        <img src="assets/img/home-one/home-bg6.jpg" alt="Expertise">
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<div class="video-wrap">
    <div class="container-fluid p-0">
        <div class="tab-content" id="pills-tabContent">
            <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                <div class="video-area">
                    <div class="d-table">
                        <div class="d-table-cell">
                            <div class="container">
                                <div class="video-item">
                                    <a href="https://www.youtube.com/watch?v=_UhdI1yjSm0" class="popup-youtube"><i class="icofont-ui-play"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="tab-pane fade" id="pills-doctor" role="tabpanel" aria-labelledby="pills-doctor-tab">
                <div class="video-area">
                    <div class="d-table">
                        <div class="d-table-cell">
                            <div class="container">
                                <div class="video-item">
                                    <a href="https://www.youtube.com/watch?v=_UhdI1yjSm0" class="popup-youtube"><i class="icofont-ui-play"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="tab-pane fade" id="pills-icu" role="tabpanel" aria-labelledby="pills-icu-tab">
                <div class="video-area">
                    <div class="d-table">
                        <div class="d-table-cell">
                            <div class="container">
                                <div class="video-item">
                                    <a href="https://www.youtube.com/watch?v=_UhdI1yjSm0" class="popup-youtube"><i class="icofont-ui-play"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">
                <div class="video-area">
                    <div class="d-table">
                        <div class="d-table-cell">
                            <div class="container">
                                <div class="video-item">
                                    <a href="https://www.youtube.com/watch?v=_UhdI1yjSm0" class="popup-youtube"><i class="icofont-ui-play"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                <div class="video-area">
                    <div class="d-table">
                        <div class="d-table-cell">
                            <div class="container">
                                <div class="video-item">
                                    <a href="https://www.youtube.com/watch?v=_UhdI1yjSm0" class="popup-youtube"><i class="icofont-ui-play"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="container">
        <ul class="video-nav nav nav-pills" id="pills-tab" role="tablist">
            <li class="nav-item video-nav-item"><a class="nav-link active" id="pills-home-tab" data-toggle="pill" href="#pills-home" role="tab" aria-controls="pills-home" aria-selected="true">Clinic Introduction</a></li>

            <li class="nav-item video-nav-item"><a class="nav-link" id="pills-doctor-tab" data-toggle="pill" href="#pills-doctor" role="tab" aria-controls="pills-doctor" aria-selected="false">Doctors</a></li>

            <li class="nav-item video-nav-item"><a class="nav-link" id="pills-icu-tab" data-toggle="pill" href="#pills-icu" role="tab" aria-controls="pills-icu" aria-selected="false">Treatment</a></li>

            <li class="nav-item video-nav-item"><a class="nav-link" id="pills-contact-tab" data-toggle="pill" href="#pills-contact" role="tab" aria-controls="pills-contact" aria-selected="false">Reasearch & Lab</a></li>

            <li class="nav-item video-nav-item"><a class="nav-link" id="pills-profile-tab" data-toggle="pill" href="#pills-profile" role="tab" aria-controls="pills-profile" aria-selected="false">Pharmacy</a></li>
        </ul>
    </div>
</div>

<section class="blog-area pt-100">
    <div class="container">
        <div class="section-title">
            <h2>Awards &amp; Recognitions</h2>
        </div>

        <div class="row">
            <div class="col-sm-6 offset-sm-3 offset-lg-0 col-lg-4 wow fadeInUp" data-wow-delay=".3s">
                <div class="blog-item">
                    <div class="blog-top">
                        <img src="assets/img/home-one/home-bg15.jpg" alt="Blog">
                    </div>

                    <div class="blog-bottom">
                        <h4>Icon of Jharkhand</h4>
                        <p>Awarded by Honourable Governer of Jharkhand Shri Ramesh Bais and Health Minister Shri Jagarnath Mahto for his excellant work in the field of psychiatry and drug de-addiction in Jharkhand.</p>
                        <ul>
                            <li><i class="icofont-google-map"></i> Ranchi, Jharkhand</li>
                            <li><i class="icofont-calendar"></i> Jul 29, 2022</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 offset-sm-3 offset-lg-0 col-lg-4 wow fadeInUp" data-wow-delay=".5s">
                <div class="blog-item">
                    <div class="blog-top">
                        <img src="assets/img/home-one/home-bg14.jpg" alt="Blog">
                    </div>

                    <div class="blog-bottom">
                        <h4>Jharkhand Ke Gaurav</h4>
                        <p>Facilitated by Education Minister of Jharkhand Shri Jagarnath Mahto for exemplary contribution in the field of psychiatry, mental health and drug de-addiction in Jharkhand.</p>
                        <ul>
                            <li><i class="icofont-google-map"></i> Ranchi, Jharkhand</li>
                            <li><i class="icofont-calendar"></i> Apr 13, 2022</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 offset-sm-3 offset-lg-0 col-lg-4 wow fadeInUp" data-wow-delay=".7s">
                <div class="blog-item">
                    <div class="blog-top">
                        <img src="assets/img/home-one/home-bg13.jpg" alt="Blog">
                    </div>

                    <div class="blog-bottom">
                        <h4>Corona Warrior</h4>
                        <p>Facilitated by Member of Parliament Shri Sanjay Seth for distinguished work in the field of Neuropsychiatry and allied branches like Drug De-addiction and sexual problems in Jharkhand.</p>
                        <ul>
                            <li><i class="icofont-google-map"></i> Ranchi, Jharkhand</li>
                            <li><i class="icofont-calendar"></i> Dec 27, 2020</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4 wow fadeInUp" data-wow-delay=".9s">
                <div class="blog-item">
                    <div class="blog-top">
                        <img src="assets/img/home-one/home-bg12.jpg" alt="Blog">
                    </div>

                    <div class="blog-bottom">
                        <h4>Doctors' Sammaan</h4>
                        <p>Awarded by State Health Minister Shri Ramchandra Chandravanshi (Government of Jharkhand) and Swami Ishwarananda Giri, General Secretary, Yogoda Satsanga Society of India.</p>
                        <ul>
                            <li><i class="icofont-google-map"></i> Ranchi, Jharkhand</li>
                            <li><i class="icofont-calendar"></i> Aug 08, 2018</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4 wow fadeInUp" data-wow-delay=".11s">
                <div class="blog-item">
                    <div class="blog-top">
                        <img src="assets/img/home-one/home-bg11.jpg" alt="Blog">
                    </div>

                    <div class="blog-bottom">
                        <h4>Architect of Jharkhand</h4>
                        <p>Awarded by Union Agriculture Minister Shri Radha Mohan Singh (Government of India) for contribution in the field of Neuropsychiatry and allied branches like Drug De-addiction and sexual problems.</p>
                        <ul>
                            <li><i class="icofont-google-map"></i> Ranchi, Jharkhand</li>
                            <li><i class="icofont-calendar"></i> Sept 27, 2016</li>
                        </ul>
                    </div>
                </div>
            </div>

        </div>

        <div class="section-title pt-100">
            <h2>Magazines &amp; Publications</h2>
        </div>

        <div class="row">
            <div class="col-sm-6 col-lg-4 wow fadeInUp" data-wow-delay=".3s">
                <div class="blog-item">
                    <div class="blog-top">
                        <a href="assets/docs/dr-pawan-kumar-barnwal-magazine-1.pdf" class="btn newsletter-btn-2" target="_blank" download="Dr. Pawan Kumar Barnwal - Body & Mind Magazine (Issue 1)"><img src="assets/docs/dr-pawan-kumar-barnwal-magazine-1.jpg" alt="Magazine 1"></a>
                    </div>

                    <div class="blog-bottom">
                        <h4>Body And Mind</h4>
                        <br>
                        <div class="newsletter-item">
                            <div class="newsletter-form">
                                <form class="newsletter-form">
                                    <a href="assets/docs/dr-pawan-kumar-barnwal-magazine-1.pdf" class="btn newsletter-btn-2" target="_blank" download="Dr. Pawan Kumar Barnwal - Body & Mind Magazine (Issue 1)">Download</a>
                                </form>
                            </div>
                        </div>
                        <br>
                        <ul>
                            <li><i class="icofont-ui-play"></i> Issue 1</li>
                            <li><i class="icofont-calendar"></i> January, 2021</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <div class="section-title pt-100">
            <h2>Functions &amp; Events</h2>
        </div>
        <section class="blog-area-two">
            <div class="container">
                <div class="row">
                    <div class="col-sm-6 col-lg-4 wow fadeInUp" data-wow-delay=".3s">
                        <div class="blog-item">
                            <div class="blog-top">
                                <a href="assets/img/events/big/8.jpg" class="popup-youtube"><img src="assets/img/events/8.jpg" alt="Events Gallery"></a>
                                <p class="functions-para">75th Azadi ka Amrit Mahotsav celebration on 15th August at Dr. Pawan Barnwal's clinic in the year 2022</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6 col-lg-4 wow fadeInUp" data-wow-delay=".5s">
                        <div class="blog-item">
                            <div class="blog-top">
                                <a href="assets/img/events/big/7.jpg" class="popup-youtube"><img src="assets/img/events/7.jpg" alt="Events Gallery"></a>
                                <p class="functions-para">Dr. Pawan Kumar Barnwal addressing delegates and fellow doctors at JIMACON, 2022</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6 col-lg-4 wow fadeInUp" data-wow-delay=".7s">
                        <div class="blog-item">
                            <div class="blog-top">
                                <a href="assets/img/events/big/1.jpg" class="popup-youtube"><img src="assets/img/events/1.jpg" alt="Events Gallery"></a>
                                <p class="functions-para">First Mark School Annual Function Inaugruration by Chief Minister of Jharkhand Sri Hemant Soren & Dr. Pawan Kumar Barnwal</p>
                            </div>
                        </div>
                    </div>

                    <div class="col-sm-6 col-lg-4 wow fadeInUp" data-wow-delay=".3s">
                        <div class="blog-item">
                            <div class="blog-top">
                                <a href="assets/img/events/big/3.jpg" class="popup-youtube"><img src="assets/img/events/3.jpg" alt="Events Gallery"></a>
                                <p class="functions-para">General meet with Dr. Pawan Kumar Barnwal & Chief Minister of Jharkhand Sri Hemant Soren</p>
                            </div>
                        </div>
                    </div>

                    <div class="col-sm-6 col-lg-4 wow fadeInUp" data-wow-delay=".5s">
                        <div class="blog-item">
                            <div class="blog-top">
                                <a href="assets/img/events/big/4.jpg" class="popup-youtube"><img src="assets/img/events/4.jpg" alt="Events Gallery"></a>
                                <p class="functions-para">Dr. Pawan Kumar Barnwal with Honourable President of India (Former Governer of Jharkhand) Smt. Draupadi Murmu presenting a souveiner of Srijan Shakti Welfare Society</p>
                            </div>
                        </div>
                    </div>

                    <div class="col-sm-6 col-lg-4 wow fadeInUp" data-wow-delay=".7s">
                        <div class="blog-item">
                            <div class="blog-top">
                                <a href="assets/img/events/big/2.jpg" class="popup-youtube"><img src="assets/img/events/2.jpg" alt="Events Gallery"></a>
                                <p class="functions-para">Dr. Pawan Kumar Barnwal with Late Dr. K.K.Sinha (Renowned Neurologist, Ranchi, Jharkhand) on New Year's Meet</p>
                            </div>
                        </div>
                    </div>

                    <div class="col-sm-6 col-lg-4 wow fadeInUp" data-wow-delay=".3s">
                        <div class="blog-item">
                            <div class="blog-top">
                                <a href="assets/img/events/big/6.jpg" class="popup-youtube"><img src="assets/img/events/6.jpg" alt="Events Gallery"></a>
                                <p class="functions-para">Dr. Pawan Kumar Barnwal along with family with Late Dr. K.K.Sinha (Renowned Neurologist, Ranchi, Jharkhand) on New Year's Meet</p>
                            </div>
                        </div>
                    </div>

                    <div class="col-sm-6 col-lg-4 wow fadeInUp" data-wow-delay=".5s">
                        <div class="blog-item">
                            <div class="blog-top">
                                <a href="assets/img/events/big/5.jpg" class="popup-youtube"><img src="assets/img/events/5.jpg" alt="Events Gallery"></a>
                                <p class="functions-para">Dr. Pawan Kumar Barnwal & his family with Union Minister of Tribal Affairs Sri Arjun Munda in public event</p>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </section>
    </div>
</section>