<div class="page-title-area page-title-four">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="page-title-item">
                <h2>Social, Occupational &amp; Family Psychiatry</h2>
                <ul>
                    <li><a routerLink="/">Home</a></li>
                    <li><i class="icofont-simple-right"></i></li>
                    <li>Diseases</li>
                    <li><i class="icofont-simple-right"></i></li>
                    <li>Social, Occupational &amp; Family Psychiatry</li>
                </ul>
            </div>
        </div>
    </div>
</div>

<section class="departments-area pt-100">
    <div class="container">
        <div class="row">
            <div class="col-sm-6 col-lg-4">
                <div class="department-item">
                    <i class="icofont-patient-bed"></i>
                    <h3>Marital Discord</h3>
                    <p>The attention given marital conflict is understandable when we consider its implications for mental, physical, and family health. Marital conflict has been linked to the onset of depressive symptoms, eating disorders, male alcoholism,
                        episodic drinking, binge drinking, and out-of-home drinking. </p>
                </div>
            </div>
            <div class="col-sm-6 col-lg-4">
                <div class="department-item">
                    <i class="icofont-patient-bed"></i>
                    <h3>Relationship Problem</h3>
                    <p>Each person in the relationship has their own set of needs, dreams, expectations and goals which they need to be aware of, and need to be able to express to others. Unstated expectations can be problematic. A successful relationship
                        needs to focus on the present and not repeatedly pick over past events.</p>
                </div>
            </div>
            <div class="col-sm-6 col-lg-4">
                <div class="department-item">
                    <i class="icofont-patient-bed"></i>
                    <h3>Pre-marital &amp; Extra-marital Problems</h3>
                    <p>Premarital counseling is a specialized type of therapy that helps couples prepare for marriage. Emotional needs form the crux of any relationship. Hence, if such needs are neglected, it could affect the foundation of the relationship
                        leading to extra-marital affairs.</p>
                </div>
            </div>
            <div class="col-sm-6 col-lg-4">
                <div class="department-item">
                    <i class="icofont-patient-bed"></i>
                    <h3>Couple Issues</h3>
                    <p>No matter the cause, distress in a relationship can lead to many problems including codependency, stress, unhappiness, depression, fear, and anxiety. You may hope your relationship troubles just go away on their own, but a troubled
                        relationship may only worsen. Timely conunselling can save a relationship in the adversities.</p>
                </div>
            </div>
            <div class="col-sm-6 col-lg-4">
                <div class="department-item">
                    <i class="icofont-patient-bed"></i>
                    <h3>Proper Parenting Issues</h3>
                    <p>Parenting is the process of promoting and supporting the physical, emotional, social, and intellectual development of a child from infancy to adulthood. Parental psychopathology, particularly in the wake of adverse experiences, can
                        strongly influence parental sensitivity and child outcomes. Professional help should be sought in such case.</p>
                </div>
            </div>
            <div class="col-sm-6 col-lg-4">
                <div class="department-item">
                    <i class="icofont-patient-bed"></i>
                    <h3>Stress (occupational, social, family, monetary, educational, political)</h3>
                    <p>Stress is the body's reaction to any change that requires an adjustment or response. The body reacts to these changes with physical, mental, and emotional responses. Stress is a normal part of life. You can experience stress from your
                        environment, your body, and your thoughts.</p>
                </div>
            </div>

        </div>
    </div>
</section>