<div class="header-top">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-sm-9 col-lg-9">
                <div class="header-top-item">
                    <div class="header-top-left">
                        <ul>
                            <li><a href="tel:06512540218"><i class="icofont-ui-call"></i> Call : 0651 254 0218</a></li>
                            <li><a href="mailto:info@drpawanbarnwal.com"><i class="icofont-ui-message"></i> info&#64;drpawanbarnwal.com</a></li>
                            <li><a routerLink="/appointment" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" style="color: red; font-size: 15px;"><strong>BOOK APPOINTMENT</strong></a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-sm-3 col-lg-3">
                <div class="header-top-item">
                    <div class="header-top-right">
                        <ul>
                            <li><a href="https://www.facebook.com/Dr-Pawan-Kumar-Barnwal-134975383786648" target="_blank"><i class="icofont-facebook"></i></a></li>
                            <li><a href="https://www.youtube.com/channel/UCVHeSPzTULB-RC09swdzSMA/" target="_blank"><i class="icofont-youtube"></i></a></li>
                            <li><a href="https://www.google.com/maps/dir//dr+pawan+barnwal+ranchi/data=!4m6!4m5!1m1!4e2!1m2!1m1!1s0x39f4e3fa0e059381:0xa4024a9f678bf4bf?sa=X&ved=2ahUKEwiS6NaavaHuAhVVzjgGHeioCdUQ9RcwC3oECBIQBQ" target="_blank"><i class="icofont-google-plus"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="navbar-area sticky-top">
    <div class="mobile-nav">
        <a routerLink="/" class="logo"><img src="assets/img/logo-two.png" width="250px" alt="Logo"></a>
    </div>

    <div class="main-nav">
        <div class="container">
            <nav class="navbar navbar-expand-md navbar-light">
                <a class="navbar-brand" routerLink="/"><img src="assets/img/logo.png" width="300px" alt="Logo" alt="Logo"></a>

                <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                    <ul class="navbar-nav">
                        <li class="nav-item">
                            <a href="#" class="nav-link">Home</a></li>

                        <li class="nav-item">
                            <a routerLink="/doctor-details" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Dr. Barnwal</a>
                        </li>

                        <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link dropdown-toggle">Diseases</a>

                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/diseases-adult" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Adult Psychiatry</a></li>

                                <li class="nav-item"><a routerLink="/diseases-child" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Child &amp; Adolescent Psychiatry</a></li>

                                <li class="nav-item"><a routerLink="/diseases-geriatric" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Geriatric (Old Age) Psychiatry</a></li>

                                <li class="nav-item"><a routerLink="/diseases-women" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Women Psychiatry</a></li>

                                <li class="nav-item"><a routerLink="/diseases-social" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Social, Occupational &amp; Family Psychiatry</a></li>

                                <li class="nav-item"><a routerLink="/diseases-drug" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Substance Abuse &amp; Addiction</a></li>

                                <li class="nav-item"><a routerLink="/diseases-sexual" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Sexual Problems</a></li>
                            </ul>
                        </li>

                        <li class="nav-item">
                            <a routerLink="/news" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">News & Media</a></li>

                        <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link dropdown-toggle">Gallery</a>

                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/photo" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Clinic Interior</a></li>

                                <li class="nav-item"><a routerLink="/video" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Videos</a></li>
                            </ul>
                        </li>
                        <li class="nav-item"><a routerLink="/appointment" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Appointment</a></li>
                    </ul>
                </div>
            </nav>
        </div>
    </div>
</div>