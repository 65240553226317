<div class="page-title-area page-title-four">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="page-title-item">
                <h2>Sexual Problems</h2>
                <ul>
                    <li><a routerLink="/">Home</a></li>
                    <li><i class="icofont-simple-right"></i></li>
                    <li>Diseases</li>
                    <li><i class="icofont-simple-right"></i></li>
                    <li>Sexual Problems</li>
                </ul>
            </div>
        </div>
    </div>
</div>

<section class="departments-area pt-100">
    <div class="container">
        <h1>Male Sexual Problems:</h1><br>
        <div class="row">
            <div class="col-sm-6 col-lg-4">
                <div class="department-item">
                    <i class="icofont-patient-bed"></i>
                    <h3>Erectile Dysfunction</h3>
                    <p>Erectile dysfunction (ED) is the inability to get or keep an erection firm enough to have sexual intercourse. It's sometimes referred to as impotence, although this term is now used less often. Occasional ED isn’t uncommon. Many men
                        experience it during times of stress. Frequent ED, however, can be a sign of health problems that need treatment. Talk therapy helps to a great extent.</p>
                </div>
            </div>
            <div class="col-sm-6 col-lg-4">
                <div class="department-item">
                    <i class="icofont-patient-bed"></i>
                    <h3>Premature Ejaculation</h3>
                    <p>Premature ejaculation (PE) is when ejaculation happens sooner than a man or his partner would like during sex. PE is also known as rapid ejaculation, premature climax or early ejaculation. PE might not be a cause for worry. It can
                        be frustrating if it makes sex less enjoyable and impacts relationships. The problem is more often psychological rather than biological.</p>
                </div>
            </div>
            <div class="col-sm-6 col-lg-4">
                <div class="department-item">
                    <i class="icofont-patient-bed"></i>
                    <h3>Hypersexuality</h3>
                    <p>Compulsive sexual behavior is sometimes called hypersexuality, hypersexuality disorder or sexual addiction. It's an excessive preoccupation with sexual fantasies, urges or behaviors that is difficult to control, causes you distress,
                        or negatively affects your health, job, relationships or other parts of your life. Get help early for problems with sexual behavior is suggested.</p>
                </div>
            </div>
            <div class="col-sm-6 col-lg-4">
                <div class="department-item">
                    <i class="icofont-patient-bed"></i>
                    <h3>Hyposexuality</h3>
                    <p>Hypoactive sexual desire disorder (HSDD), hyposexuality or inhibited sexual desire (ISD) is considered a sexual dysfunction and is characterized as a lack or absence of sexual fantasies and desire for sexual activity, as judged by
                        a clinician. It must cause marked distress or interpersonal difficulties and not be better accounted for by another mental disorder or a drug.</p>
                </div>
            </div>
            <div class="col-sm-6 col-lg-4">
                <div class="department-item">
                    <i class="icofont-patient-bed"></i>
                    <h3>Poor Sexual Knowledge</h3>
                    <p>Currently, in many societies, unmarried, single youths are sexually more active than commonly realized. Adolescents also maintain sexual relationships, even though cultural values in countries like India do not permit this. Sexual
                        behaviors of adolescents depend on their knowledge about sex, attitudes and sources of influence.</p>
                </div>
            </div>
            <div class="col-sm-6 col-lg-4">
                <div class="department-item">
                    <i class="icofont-patient-bed"></i>
                    <h3>Penile Dysmorphic Disorder</h3>
                    <p>Penile dysmorphic disorder (PDD) is shorthand for men diagnosed with body dysmorphic disorder, in whom the size or shape of the penis is their main, if not their exclusive, preoccupation causing significant shame or handicap. Experts
                        estimate that between 1% and 3% of men have PDD.</p>
                </div>
            </div>
            <div class="col-sm-6 col-lg-4">
                <div class="department-item">
                    <i class="icofont-patient-bed"></i>
                    <h3>Dhat Syndrome</h3>
                    <p>Dhat syndrome (Sanskrit: धातु दोष) is a condition found in the cultures of South Asia (including Pakistan, India, Bangladesh, Nepal, and Sri Lanka) in which male patients report that they suffer from premature ejaculation or impotence,
                        and believe that they are passing semen in their urine. It generally refer to anxiety and hypochondriacal concerns.</p>
                </div>
            </div>
            <div class="col-sm-6 col-lg-4">
                <div class="department-item">
                    <i class="icofont-patient-bed"></i>
                    <h3>Nocturnal Emission</h3>
                    <p>A nocturnal emission, informally known as an wet dream, sex dream, nightfall or sleep orgasm, is a spontaneous orgasm during sleep that includes ejaculation for a male, or vaginal wetness or an orgasm (or both) for a female. Nocturnal
                        emissions are most common during adolescence and early young adult years, but they may happen any time after puberty.</p>
                </div>
            </div>
            <div class="col-sm-6 col-lg-4">
                <div class="department-item">
                    <i class="icofont-patient-bed"></i>
                    <h3>Loss of Libido</h3>
                    <p>Loss of libido (sex drive) is a common problem that affects many men and women at some point in their life. It's often linked to relationship issues, stress or tiredness, but can be a sign of an underlying medical problem, such as
                        reduced hormone levels. If lack of desire for sex is distressing or it's affecting one's relationship, it's a good idea to get help from a professional. </p>
                </div>
            </div>

        </div>
    </div>

    <br>
    <div class="container">
        <h1>Female Sexual Problems:</h1><br>
        <div class="row">
            <div class="col-sm-6 col-lg-4">
                <div class="department-item">
                    <i class="icofont-patient-bed"></i>
                    <h3>Hypersexuality</h3>
                    <p>Women who have sex so frequently that it may cause them problems — sometimes referred to as being "hypersexual" — seem to be characterized more by their high rates of masturbation and pornography use, rather than passive forms of sexual
                        behavior, such as having fantasies, as previous studies had suggested, according to new research.</p>
                </div>
            </div>
            <div class="col-sm-6 col-lg-4">
                <div class="department-item">
                    <i class="icofont-patient-bed"></i>
                    <h3>Female Sexual Arousal Disorder</h3>
                    <p>Female sexual arousal disorder (FSAD) is a disorder characterized by a persistent or recurrent inability to attain sexual arousal or to maintain arousal until the completion of a sexual activity. The diagnosis can also refer to an
                        inadequate lubrication-swelling response normally present during arousal and sexual activity.</p>
                </div>
            </div>
            <div class="col-sm-6 col-lg-4">
                <div class="department-item">
                    <i class="icofont-patient-bed"></i>
                    <h3>Poor Sexual Knowledge</h3>
                    <p>Good reproductive health should include freedom from risk of sexually transmitted diseases, the right to regulate one’s own fertility with full knowledge of contraceptive choices, and the ability to control sexuality without being
                        discriminated against because of age, marital status, income, or similar considerations.</p>
                </div>
            </div>
            <div class="col-sm-6 col-lg-4">
                <div class="department-item">
                    <i class="icofont-patient-bed"></i>
                    <h3>Dyspareunia</h3>
                    <p>Dyspareunia is the term for recurring pain in the genital area or within the pelvis during sexual intercourse. The pain can be sharp or intense. It can occur before, during, or after sexual intercourse. Dyspareunia is more common in
                        women than men. It has many possible causes, but it can be treated. Alternatives to sexual intercourse may be useful until underlying conditions are treated.</p>
                </div>
            </div>
            <div class="col-sm-6 col-lg-4">
                <div class="department-item">
                    <i class="icofont-patient-bed"></i>
                    <h3>Loss of libido</h3>
                    <p>Women's sexual desires naturally fluctuate over the years. Highs and lows commonly coincide with the beginning or end of a relationship or with major life changes, such as pregnancy, menopause or illness. If lack of interest in sex
                        continues or returns and causes personal distress, one may have a condition called sexual interest/arousal disorder. Mental health problems can also be causes.</p>
                </div>
            </div>

        </div>
    </div>
</section>