<div class="page-title-area page-title-four">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="page-title-item">
                <h2>Videos</h2>
                <ul>
                    <li><a routerLink="#">Home</a></li>
                    <li><i class="icofont-simple-right"></i></li>
                    <li>Gallery</li>
                    <li><i class="icofont-simple-right"></i></li>
                    <li>Videos</li>
                </ul>
            </div>
        </div>
    </div>
</div>

<section class="blog-area-two pt-100">
    <div class="container">
        <div class="row" style="text-align: center;">
            <div class="video-frame col-md-6 wow fadeInUp" data-wow-delay=".3s">
                <iframe src="https://www.youtube.com/embed/bB0tCw9HU78" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                <h5>Increasing trend of depression in Gen Z</h5>
                <p>An interview by Prabhat Khabar</p>
            </div>
            <div class="video-frame col-md-6 wow fadeInUp" data-wow-delay=".3s">
                <iframe src="https://www.youtube.com/embed/fL_P97CVsrY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                <h5>75th Azadi ka Amrit Mahotsav</h5>
                <p>Independance day celebration at Dr. Pawan Barnwal's Clinic on 15th August, 2022</p>
            </div>
        </div>
        <div class="row" style="text-align: center;">
            <div class="video-frame col-md-6 wow fadeInUp" data-wow-delay=".3s">
                <iframe src="https://www.youtube.com/embed/Mv96HPHn2Xc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                <h5>Ragging and its psychological consequences</h5>
                <p>A Documentary Film by Dr. Sushil Kumar 'Ankan' ft. Dr. Pawan Kumar Barnwal</p>
            </div>
            <div class="video-frame col-md-6 wow fadeInUp" data-wow-delay=".3s">
                <iframe src="https://www.youtube.com/embed/_UhdI1yjSm0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                <h5>Know Your Psychiatrist</h5>
                <p>About Dr. Pawan Kumar Barnwal</p>
            </div>
        </div>
        <div class="row" style="text-align: center;"> 
            <div class="video-frame col-md-6 wow fadeInUp" data-wow-delay=".3s">
                <iframe src="https://www.youtube.com/embed/r1StpM4RS4s" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                <h5>Prevention from COVID 19</h5>
                <p>A compedium on the ongoing pandemic</p>
            </div>
            <div class="video-frame col-md-6 wow fadeInUp" data-wow-delay=".5s">
                <iframe src="https://www.youtube.com/embed/PRyXRbDVG00" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                <h5>Problem of Forgetting</h5>
                <p>An interview by Radio Dhoom RJ Faizan</p>
            </div>
        </div>
        <div class="row" style="text-align: center;">
            <div class="video-frame col-md-6 wow fadeInUp" data-wow-delay=".3s">
                <iframe src="https://www.youtube.com/embed/vxAGPY71Sog" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                <h5>Suicide Prevention</h5>
                <p>An interview by Orchid Hospital, Ranchi</p>
            </div>
            <div class="video-frame col-md-6 wow fadeInUp" data-wow-delay=".5s">
                <iframe src="https://www.youtube.com/embed/Cq34DC0O9Qg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                <h5>Take these measures to avoid disease</h5>
                <p>An interview by Time Facts News</p>
            </div>
        </div>
    </div>
</section>