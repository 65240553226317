<div class="page-title-area page-title-four">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="page-title-item">
                <h2>Substance Abuse &amp; Addiction</h2>
                <ul>
                    <li><a routerLink="/">Home</a></li>
                    <li><i class="icofont-simple-right"></i></li>
                    <li>Diseases</li>
                    <li><i class="icofont-simple-right"></i></li>
                    <li>Substance Abuse &amp; Addiction</li>
                </ul>
            </div>
        </div>
    </div>
</div>

<section class="departments-area pt-100">
    <div class="container">
        <div class="row">
            <div class="col-sm-6 col-lg-4">
                <div class="department-item">
                    <i class="icofont-patient-bed"></i>
                    <h3>Alcohol</h3>
                    <p>Drinking alcohol too much or too often, or being unable to control alcohol consumption, can be a sign of a larger problem. Two different issues that some people can develop are alcohol abuse or alcoholism, also known as alcohol dependency.
                        People who abuse alcohol drink too much on occasion and their drinking habits often result in risky behavior and poor judgment.</p>
                </div>
            </div>
            <div class="col-sm-6 col-lg-4">
                <div class="department-item">
                    <i class="icofont-patient-bed"></i>
                    <h3>Nicotine</h3>
                    <p>Nicotine dependence occurs when you need nicotine and can't stop using it. Nicotine is the chemical in tobacco that makes it hard to quit. Nicotine produces pleasing effects in your brain, but these effects are temporary. So you reach
                        for another cigarette. Tobacco smoke contains more than 60 known cancer-causing chemicals and thousands of other harmful substances.</p>
                </div>
            </div>
            <div class="col-sm-6 col-lg-4">
                <div class="department-item">
                    <i class="icofont-patient-bed"></i>
                    <h3>Opioid</h3>
                    <p>Opioid addiction is characterized by a powerful, compulsive urge to use opioid drugs, even when they are no longer required medically. Opioids have a high potential for causing addiction in some people, even when the medications are
                        prescribed appropriately and taken as directed. People who become addicted may prioritize getting and using these drugs over other activities in their lives.</p>
                </div>
            </div>
            <div class="col-sm-6 col-lg-4">
                <div class="department-item">
                    <i class="icofont-patient-bed"></i>
                    <h3>Solvent Sniffing</h3>
                    <p>Solvent sniffing like dendrite, whitener etc are cheap, but dangerous way people have used to get high for many years. It can be life-threatening. Even if the result isn’t fatal, the risks associated with glue and other inhalants include
                        possible brain damage and severe breathing problems. The damage can cause long-term harm to brain function, causing neurological problems similar to multiple sclerosis.</p>
                </div>
            </div>
            <div class="col-sm-6 col-lg-4">
                <div class="department-item">
                    <i class="icofont-patient-bed"></i>
                    <h3>Addictive Tablets (Benzodiazepine)</h3>
                    <p>Benzodiazepine abuse is more common than you may think. Left untreated, abusing these drugs can negatively impact your relationships, career, and your physical and emotional health. Benzodiazepines are a type of medication known as
                        tranquilizers. Familiar names include Valium and Xanax. seizures are withdrawal symptom of Benzodiazepine.</p>
                </div>
            </div>
            <div class="col-sm-6 col-lg-4">
                <div class="department-item">
                    <i class="icofont-patient-bed"></i>
                    <h3>Cocaine</h3>
                    <p>Any use of cocaine is considered abuse because it is an illegal substance. Cocaine is a central nervous system stimulant that affects the brain by stimulating high levels of dopamine, a brain chemical associated with pleasure and reward.
                        The psychological addiction is often the hardest part to overcome, although there are undeniable physical symptoms of addiction as well.</p>
                </div>
            </div>
            <div class="col-sm-6 col-lg-4">
                <div class="department-item">
                    <i class="icofont-patient-bed"></i>
                    <h3>LSD</h3>
                    <p>LSD is known for its profound changes in consciousness and perception. During a “trip,” users experience a wide variety of effects, most often visual and other sensory distortions, changes to thought processes, intense emotions, and
                        for some people, surprising new insights and life revelations. LSD’s effects typically last around 8-10 hours, with peak effects occurring 4-6 hours after ingestion.</p>
                </div>
            </div>
            <div class="col-sm-6 col-lg-4">
                <div class="department-item">
                    <i class="icofont-patient-bed"></i>
                    <h3>Charas</h3>
                    <p>When a person gets addicted to drugs, it is not only him/ her who endures but the whole family agonises over the pain. The WHO reports that more than 2.5% of the global population consumes cannabis. Cannabis abuse has grown more rapidly
                        than cocaine and opiate abuse recently. The effects of charas can be powerful and dangerous. The active ingredient in charas is THC.</p>
                </div>
            </div>
            <div class="col-sm-6 col-lg-4">
                <div class="department-item">
                    <i class="icofont-patient-bed"></i>
                    <h3>Ganja</h3>
                    <p>Marijuana is the most commonly used illicit drug in the United States, typically smoked as a hand-rolled cigarette (joint) or in a pipe or water pipe (bong). The drug is also smoked in what’s called a blunt—a cigar that’s been emptied
                        of tobacco and refilled with a mixture of marijuana and tobacco. Addiction to marijuana is most commonly diagnosed during adolescence or young adulthood.</p>
                </div>
            </div>
            <div class="col-sm-6 col-lg-4">
                <div class="department-item">
                    <i class="icofont-patient-bed"></i>
                    <h3>Bhang</h3>
                    <p>Bhang is the preparation made from the Cannabis sativa plant, which is usually used in the food and drinks during Holi. Though many say it's okay to have bhang and that it is safe, it isn’t exactly true. Bhang does have some hallucinogenic,
                        physical as well as psychological effects on the mind and body. Bhang overdose can also lead to Bhang addiction. Cognitive behavioural therapy (CBT) is an effective treatment method</p>
                </div>
            </div>
            <div class="col-sm-6 col-lg-4">
                <div class="department-item">
                    <i class="icofont-patient-bed"></i>
                    <h3>Proxyvon</h3>
                    <p>Proxyvon tablet is the worst addiction in current scenerio. Being cheap and pharmasist selling in black with huge margin is easily availabe on the counter,only if u have settings. Some use them by swallowing and some mix it with liquid
                        and inject themselves. The veins and nerves of all body starts disappearing. It is usually combined with other prescribed medicines it give double high.</p>
                </div>
            </div>
            <div class="col-sm-6 col-lg-4">
                <div class="department-item">
                    <i class="icofont-patient-bed"></i>
                    <h3>Cough Syrup</h3>
                    <p>Cough syrup abuse has been a problem for decades. It has become popular among teens who want a cheap, easy high. But the concern in the past had been about the alcohol and codeine in cough and cold products. Because of that, the alcohol
                        has been removed from most OTC cough and cold remedies. Regular abuse at high doses can lead to a chemical psychosis. That’s when a person loses contact with reality.</p>
                </div>
            </div>
            <div class="col-sm-6 col-lg-4">
                <div class="department-item">
                    <i class="icofont-patient-bed"></i>
                    <h3>Heroine</h3>
                    <p>Heroin also called brown sugar is a drug that comes from a flower, the opium poppy, which usually grows in Mexico, Asia, and South America. It’s very addictive. Many people who take it develop a use disorder. This means it causes health
                        problems, disabilities, and trouble at home, work, or school. Cognitive behavioral therapy helps to pay attention to the things you think and do when it comes to drug use.</p>
                </div>
            </div>
            <div class="col-sm-6 col-lg-4">
                <div class="department-item">
                    <i class="icofont-patient-bed"></i>
                    <h3>Pentazocine injection</h3>
                    <p>Pentazocine injection is an opioid and has very high potential for abuse and dependency. Here are some of the common and serious side effects of it - Respiratory depression and death due to inability to breathe, granulocytopenia, severe
                        and serious drop in blood pressure leading to coma and death, seizures and anaphylactic reactions. The person may also have nausea, vomiting, dizziness, facial edema and blurry vision.</p>
                </div>
            </div>
            <div class="col-sm-6 col-lg-4">
                <div class="department-item">
                    <i class="icofont-patient-bed"></i>
                    <h3>Tobacco</h3>
                    <p>Nicotine is the main addictive chemical in tobacco (chewed or smoked). It causes a rush of adrenaline when absorbed in the bloodstream or inhaled via cigarette smoke. Nicotine also triggers an increase in dopamine. This is sometimes
                        referred to as the brain’s “happy” chemical. Some people who use tobacco have success with methods such as hypnotherapy, cognitive-behavioral therapy, neuro-linguistic programming.</p>
                </div>
            </div>
            <div class="col-sm-6 col-lg-4">
                <div class="department-item">
                    <i class="icofont-patient-bed"></i>
                    <h3>Gutkha</h3>
                    <p>Gutka, ghutka, guṭkha or betel quid is a chewing tobacco preparation made of crushed areca nut (also called betel nut), tobacco, catechu, paraffin wax, slaked lime (Calcium oxide) and sweet or savory flavourings. In addition to cancer
                        of the head, mouth, neck, throat, oesophagus, other aerodigestive tract cancers, and dental disease areca nut, the main ingredient in gutka is known to cause severe oral mucosal disorders.</p>
                </div>
            </div>
            <div class="col-sm-6 col-lg-4">
                <div class="department-item">
                    <i class="icofont-patient-bed"></i>
                    <h3>Alprazolam</h3>
                    <p>Alprazolam abuse is partially related to the toxic effects that they produce and also to their widespread availability. They can be chronically abused or, as seen more commonly in hospital emergency departments, intentionally or accidentally
                        taken in overdose.It is one of the most widely prescribed benzodiazepines for the treatment of generalized anxiety disorder & panic disorder.</p>
                </div>
            </div>
            <div class="col-sm-6 col-lg-4">
                <div class="department-item">
                    <i class="icofont-patient-bed"></i>
                    <h3>Nitrazepam (N10)</h3>
                    <p>Nitrazepam is one of the most addictive and easily available sleeping pill that we have available in India. It acts very fast, gives instant relaxation and usually does not give a bad hangover. It is the second most commonly abused
                        sleeping pill. Sleeping pill withdrawal can be life-threatening, one should not attempt to take the straight and narrow road all of a sudden without medical supervision.</p>
                </div>
            </div>
            <div class="col-sm-6 col-lg-4">
                <div class="department-item">
                    <i class="icofont-patient-bed"></i>
                    <h3>Diazepam</h3>
                    <p>Diazepam is most often used by people who need help dealing with the stress of daily life. These people are also the ones most likely to abuse it. While there are several reasons for Valium abuse, many of those abusing the drug don’t
                        take it to get high. They take it to feel normal — to relieve stress and anxiety. Valium produces a sense of intense calm and euphoria, especially in higher doses.</p>
                </div>
            </div>
            <div class="col-sm-6 col-lg-4">
                <div class="department-item">
                    <i class="icofont-patient-bed"></i>
                    <h3>Sniffing glue</h3>
                    <p>Sniffing glue can be life-threatening. Even if the result isn’t fatal, the risks associated with glue and other inhalants include possible brain damage and severe breathing problems. Dendrite is one of the most abused glue used by
                        kids to get a high. Those that include the solvents toluene and naphthalene — can damage the myelin sheath, the thin covering around the nerve fibers in the brain.</p>
                </div>
            </div>
            <div class="col-sm-6 col-lg-4">
                <div class="department-item">
                    <i class="icofont-patient-bed"></i>
                    <h3>Whitener</h3>
                    <p>If a child is making too many mistakes in his homework and using whitener too frequently then probably it is time to take note. The child may be addicted to the whitener and its diluter, which is a silent but rapidly growing addictive
                        item among adolescents. They act on the nervous system and alter brain function, resulting in temporary change in perception, mood, consciousness and behaviour.</p>
                </div>
            </div>

        </div>
    </div>
</section>