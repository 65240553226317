<div class="page-title-area page-title-four">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="page-title-item">
                <h2>Geriatric (Old Age) Psychiatry</h2>
                <ul>
                    <li><a routerLink="/">Home</a></li>
                    <li><i class="icofont-simple-right"></i></li>
                    <li>Diseases</li>
                    <li><i class="icofont-simple-right"></i></li>
                    <li>Geriatric (Old Age) Psychiatry</li>
                </ul>
            </div>
        </div>
    </div>
</div>

<section class="departments-area pt-100">
    <div class="container">
        <div class="row">
            <div class="col-sm-6 col-lg-4">
                <div class="department-item">
                    <i class="icofont-patient-bed"></i>
                    <h3>Dementia</h3>
                    <p>Dementia is a term used to describe various symptoms of cognitive decline, such as forgetfulness. It's a symptom of several underlying diseases and brain disorders. It's not a single disease in itself, but a general term to describe
                        symptoms of impairment in memory, communication, and thinking. While the likelihood of having dementia increases with age, it is not a normal part of aging.</p>
                </div>
            </div>
            <div class="col-sm-6 col-lg-4">
                <div class="department-item">
                    <i class="icofont-patient-bed"></i>
                    <h3>Alzeimer’s Disease</h3>
                    <p>Alzheimer's disease (AD) is a neurodegenerative disease that usually starts slowly and gradually worsens over time. It is the cause of 60–70% of cases of dementia. The most common early symptom is difficulty in remembering recent events.
                        As the disease advances, symptoms can include problems with language, disorientation, mood swings, loss of motivation, self-neglect.</p>
                </div>
            </div>
            <div class="col-sm-6 col-lg-4">
                <div class="department-item">
                    <i class="icofont-patient-bed"></i>
                    <h3>Behavioural Changes</h3>
                    <p>Aging brings a cascade of physical and emotional changes, which can lead to a variety of behavioral changes in older adult. It leads to memory lapses or forgetfulness, low mood after experiencing loss, discouragement or anger as health
                        declines and taking longer to learn new things. Sadness lasting for many weeks may indicate a complicated grief response. </p>
                </div>
            </div>
            <div class="col-sm-6 col-lg-4">
                <div class="department-item">
                    <i class="icofont-patient-bed"></i>
                    <h3>Geriatric Depression</h3>
                    <p>Geriatric depression is a mental and emotional disorder affecting older adults. Feelings of sadness and occasional "blue" moods are normal. However, lasting depression is not a typical part of aging. Older adults are more likely to
                        suffer from subsyndromal depression. This type of depression doesn’t always meet the full criteria for major depression. It can lead to major depression if left untreated.</p>
                </div>
            </div>
            <div class="col-sm-6 col-lg-4">
                <div class="department-item">
                    <i class="icofont-patient-bed"></i>
                    <h3>Geriatric Anxiety</h3>
                    <p>Geriatric anxiety refers to anxiety disorders among the elderly. Anxiety among the elderly is similar to that in the younger population — the person feels worry, fear or doubt. However, it occurs with higher intensity and often, along
                        with other physical and mental illnesses. Anxiety is common among the elderly but is generally overlooked as a sign of aging. Physical illness increases the risk of geriatric anxiety.</p>
                </div>
            </div>
            <div class="col-sm-6 col-lg-4">
                <div class="department-item">
                    <i class="icofont-patient-bed"></i>
                    <h3>Geriatric Psychosis</h3>
                    <p>Research has shown that elderly patients are especially at risk for the development of psychotic symptoms. A combination of factors contributes to the increased risk for psychosis in this patient population. Various DSM-IV diagnostic
                        categories including delirium, schizophrenia, delusional disorder, mood disorders, dementia, substance abuse, and medical-neurologic conditions can be associated with psychotic symptoms.</p>
                </div>
            </div>

        </div>
    </div>
</section>