import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PreloaderComponent } from './components/layouts/preloader/preloader.component';
import { FooterComponent } from './components/layouts/footer/footer.component';
import { NavbarComponent } from './components/layouts/navbar/navbar.component';
import { HomeOneComponent } from './components/pages/home-one/home-one.component';
import { AppointmentComponent } from './components/pages/appointment/appointment.component';
import { PrivacyPolicyComponent } from './components/pages/privacy-policy/privacy-policy.component';
import { RefundPolicyComponent } from './components/pages/refund-policy/refund-policy.component';
import { TermsComponent } from './components/pages/terms/terms.component';
import { ErrorComponent } from './components/pages/error/error.component';
import { DoctorDetailsComponent } from './components/pages/doctor-details/doctor-details.component';
import { ContactComponent } from './components/pages/contact/contact.component';
import { NewsComponent } from './components/pages/news-media/news.component';
import { DiseasesAdultComponent } from './components/pages/diseases-adult/diseases-adult.component';
import { DiseasesChildComponent } from './components/pages/diseases-child/diseases-child.component';
import { DiseasesGeriatricComponent } from './components/pages/diseases-geriatric/diseases-geriatric.component';
import { DiseasesWomenComponent } from './components/pages/diseases-women/diseases-women.component';
import { DiseasesSocialComponent } from './components/pages/diseases-social/diseases-social.component';
import { DiseasesDrugComponent } from './components/pages/diseases-drug/diseases-drug.component';
import { DiseasesSexualComponent } from './components/pages/diseases-sexual/diseases-sexual.component';

@NgModule({
  declarations: [
    AppComponent,
    PreloaderComponent,
    FooterComponent,
    NavbarComponent,
    HomeOneComponent,
    AppointmentComponent,
    PrivacyPolicyComponent,
    RefundPolicyComponent,
    TermsComponent,
    ErrorComponent,
    DoctorDetailsComponent,
    ContactComponent,
    NewsComponent,
    DiseasesAdultComponent,
    DiseasesChildComponent,
    DiseasesGeriatricComponent,
    DiseasesWomenComponent,
    DiseasesSocialComponent,
    DiseasesDrugComponent,
    DiseasesSexualComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule
  ],
  providers: [ ],
  bootstrap: [AppComponent]
})
export class AppModule { }
