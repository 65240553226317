<div class="page-title-area page-title-four">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="page-title-item">
                <h2>Adult Psychiatry</h2>
                <ul>
                    <li><a routerLink="/">Home</a></li>
                    <li><i class="icofont-simple-right"></i></li>
                    <li>Diseases</li>
                    <li><i class="icofont-simple-right"></i></li>
                    <li>Adult Psychiatry</li>
                </ul>
            </div>
        </div>
    </div>
</div>

<section class="departments-area pt-100">
    <div class="container">
        <div class="row">
            <div class="col-sm-6 col-lg-4">
                <div class="department-item">
                    <i class="icofont-patient-bed"></i>
                    <h3>Depression</h3>
                    <p>Depression is a state of low mood and aversion to activity. It affects a person's thoughts, behavior, motivation, feelings, and sense of well-being. Symptom of depression is anhedonia, which refers to loss of interest or a loss of
                        feeling of pleasure in certain activities that usually bring joy to people. Depressed mood is a symptom of mood disorders such as major depressive disorder or dysthymia.</p>
                </div>
            </div>
            <div class="col-sm-6 col-lg-4">
                <div class="department-item">
                    <i class="icofont-patient-bed"></i>
                    <h3>Suicide</h3>
                    <p>Suicide is the act of intentionally causing one's own death. Mental disorders—including depression, bipolar disorder, autism spectrum disorders, schizophrenia, personality disorders, anxiety disorders, physical disorders such as chronic
                        fatigue syndrome, and substance abuse—including alcoholism and the use of and withdrawal from benzodiazepines—are risk factors.</p>
                </div>
            </div>
            <div class="col-sm-6 col-lg-4">
                <div class="department-item">
                    <i class="icofont-patient-bed"></i>
                    <h3>Deliberate Self-harm</h3>
                    <p>Deliberate Self-harm or self-injury is intentional direct injury of one's own skin tissues usually without a suicidal intention. Other terms such as cutting and self-mutilation have been used for any self-harming behavior regardless
                        of suicidal intent. The most common form of self-harm is using a sharp object to cut the skin. Other forms include scratching, hitting, or burning body parts.</p>
                </div>
            </div>
            <div class="col-sm-6 col-lg-4">
                <div class="department-item">
                    <i class="icofont-patient-bed"></i>
                    <h3>Generalized Anxiety Disorder</h3>
                    <p>Generalized anxiety disorder is an anxiety disorder characterized by excessive, uncontrollable and often irrational worry about events or activities. Worry often interferes with daily functioning, and sufferers are overly concerned
                        about everyday matters such as health, finances, death, family, relationship concerns, or work difficulties.</p>
                </div>
            </div>
            <div class="col-sm-6 col-lg-4">
                <div class="department-item">
                    <i class="icofont-patient-bed"></i>
                    <h3>Phobia</h3>
                    <p>A phobia is a type of anxiety disorder defined by a persistent and excessive fear of an object or situation. Phobias typically result in a rapid onset of fear and are present for more than six months. Those affected will go to great
                        lengths to avoid the situation or object, to a degree greater than the actual danger posed. If the object or situation cannot be avoided, they experience significant distress.</p>
                </div>
            </div>
            <div class="col-sm-6 col-lg-4">
                <div class="department-item">
                    <i class="icofont-patient-bed"></i>
                    <h3>Panic Disorder</h3>
                    <p>Panic disorder is an anxiety disorder characterized by reoccurring unexpected panic attacks. Panic attacks are sudden periods of intense fear that may include palpitations, sweating, shaking, shortness of breath, numbness, or a feeling
                        that something terrible is going to happen. The maximum degree of symptoms occurs within minutes. There may be ongoing worries about having further attacks.</p>
                </div>
            </div>
            <div class="col-sm-6 col-lg-4">
                <div class="department-item">
                    <i class="icofont-patient-bed"></i>
                    <h3>Obsessive Compulsive Disorder</h3>
                    <p>Obsessive–compulsive disorder (OCD) is a mental disorder in which a person has certain thoughts repeatedly (called "obsessions") or feels the need to perform certain routines repeatedly (called "compulsions") to an extent which generates
                        distress or impairs general functioning. The person is unable to control either the thoughts or activities for more than a short period of time.</p>
                </div>
            </div>
            <div class="col-sm-6 col-lg-4">
                <div class="department-item">
                    <i class="icofont-patient-bed"></i>
                    <h3>Dissociative Disorder</h3>
                    <p>Dissociative disorders (DD) are conditions that involve disruptions or breakdowns of memory, awareness, identity, or perception. People with dissociative disorders use dissociation as a defense mechanism, pathologically and involuntarily.
                        The individual suffers these dissociations to protect themselves. Some dissociative disorders are triggered by psychological trauma. The lifetime prevalence of dissociative disorders is 10% in the general population.</p>
                </div>
            </div>
            <div class="col-sm-6 col-lg-4">
                <div class="department-item">
                    <i class="icofont-patient-bed"></i>
                    <h3>Trance &amp; Possession Disorder</h3>
                    <p>Trance and possession disorders are defined as states involving 'a temporary loss of the sense of personal identity and full awareness of the surroundings', and are classed as a type of dissociative disorder. Possession trance is characterized
                        by a transient alteration in identity whereby one's normal identity is temporarily replaced (possessed) by a spirit, ghost, deity, or other person.</p>
                </div>
            </div>
            <div class="col-sm-6 col-lg-4">
                <div class="department-item">
                    <i class="icofont-patient-bed"></i>
                    <h3>Post Traumatic Stress Disorder</h3>
                    <p>Post-traumatic stress disorder (PTSD) is a mental disorder that can develop after a person is exposed to a traumatic event, such as sexual assault, warfare, traffic collisions, child abuse, or other threats on a person's life. Symptoms
                        may include disturbing thoughts, feelings, or dreams related to the events, mental or physical distress to trauma-related cues, attempts to avoid trauma-related cues.</p>
                </div>
            </div>
            <div class="col-sm-6 col-lg-4">
                <div class="department-item">
                    <i class="icofont-patient-bed"></i>
                    <h3>Migraine</h3>
                    <p>Migraine is a primary headache disorder characterized by recurrent headaches that are moderate to severe. Typically, episodes affect one half of the head, are pulsating in nature, and last from a few hours to 3 days. Associated symptoms
                        may include nausea, vomiting, and sensitivity to light, sound, or smell. The pain is generally made worse by physical activity, although regular exercise may have prophylactic effects.</p>
                </div>
            </div>
            <div class="col-sm-6 col-lg-4">
                <div class="department-item">
                    <i class="icofont-patient-bed"></i>
                    <h3>Headache</h3>
                    <p>Headache is the symptom of pain in the face, head, or neck. It can occur as a migraine, tension-type headache, or cluster headache. Frequent headaches can affect relationships and employment. There is also an increased risk of depression
                        in those with severe headaches. Tension headaches are the most common, affecting about 1.6 billion people (21.8% of the population) followed by migraine headaches.</p>
                </div>
            </div>
            <div class="col-sm-6 col-lg-4">
                <div class="department-item">
                    <i class="icofont-patient-bed"></i>
                    <h3>Schizophrenia</h3>
                    <p>Schizophrenia is a psychiatric disorder characterized by continuous or relapsing episodes of psychosis. Major symptoms include hallucinations (hearing voices), delusions, and disorganized thinking. Other symptoms include social withdrawal,
                        decreased emotional expression, and apathy. Symptoms come on gradually, begin in young adulthood, and in many cases never resolve.</p>
                </div>
            </div>
            <div class="col-sm-6 col-lg-4">
                <div class="department-item">
                    <i class="icofont-patient-bed"></i>
                    <h3>Psychosis</h3>
                    <p>Psychosis is an abnormal condition of the mind that results in difficulties determining what is real and what is not real. Symptoms may include delusions and hallucinations. Other symptoms may include incoherent speech and behavior
                        that is inappropriate for the situation. There may also be sleep problems, social withdrawal, lack of motivation, and difficulties carrying out daily activities.</p>
                </div>
            </div>
            <div class="col-sm-6 col-lg-4">
                <div class="department-item">
                    <i class="icofont-patient-bed"></i>
                    <h3>Bipolar Disorder</h3>
                    <p>Bipolar disorder is characterized by periods of depression and periods of abnormally elevated mood. If the elevated mood is severe or associated with psychosis, it is called mania; if it is less severe, it is called hypomania. During
                        mania, an individual behaves or feels abnormally energetic, happy, or irritable, and they often make impulsive decisions with little regard for the consequences.</p>
                </div>
            </div>
            <div class="col-sm-6 col-lg-4">
                <div class="department-item">
                    <i class="icofont-patient-bed"></i>
                    <h3>Manic Episode</h3>
                    <p>A manic episode is characterized by a sustained period of abnormally elevated or irritable mood, intense energy, racing thoughts, and other extreme and exaggerated behaviors. People can also experience psychosis, including hallucinations
                        and delusions, which indicate a separation from reality. Beyond medication and therapy, a few relatively simple lifestyle changes can help.</p>
                </div>
            </div>
            <div class="col-sm-6 col-lg-4">
                <div class="department-item">
                    <i class="icofont-patient-bed"></i>
                    <h3>Epilepsy (Seizure disorder)</h3>
                    <p>Epilepsy (Seizure Disorder) is a group of neurological disorders characterized by recurrent epileptic seizures. Epileptic seizures are episodes that can vary from brief and nearly undetectable periods to long periods of vigorous shaking.
                        These episodes can result in physical injuries, including occasionally broken bones. Seizures have a tendency to recur and have no immediate underlying cause.</p>
                </div>
            </div>
            <div class="col-sm-6 col-lg-4">
                <div class="department-item">
                    <i class="icofont-patient-bed"></i>
                    <h3>Hypomania</h3>
                    <p>Hypomania (literally "under mania" or "less than mania") is a mood state characterized by persistent disinhibition and mood elevation (euphoria), with behavior that is noticeably different from the person's typical behavior when in
                        a non-depressed state. It may involve irritability, not necessarily less severe than full mania.</p>
                </div>
            </div>
            <div class="col-sm-6 col-lg-4">
                <div class="department-item">
                    <i class="icofont-patient-bed"></i>
                    <h3>Personality Disorder</h3>
                    <p>Personality disorders (PD) are a class of mental disorders characterized by enduring maladaptive patterns of behavior, cognition, and inner experience, exhibited across many contexts and deviating from those accepted by the individual's
                        culture. These patterns develop early, are inflexible, and are associated with significant distress or disability.</p>
                </div>
            </div>
            <div class="col-sm-6 col-lg-4">
                <div class="department-item">
                    <i class="icofont-patient-bed"></i>
                    <h3>Somatoform disorder</h3>
                    <p>A somatic symptom disorder, formerly known as a somatoform disorder, is any mental disorder that manifests as physical symptoms that suggest illness or injury, but cannot be explained fully by a general medical condition or by the
                        direct effect of a substance, and are not attributable to another mental disorder. Symptoms are sometimes similar to those of other illnesses and may last for years. </p>
                </div>
            </div>
            <div class="col-sm-6 col-lg-4">
                <div class="department-item">
                    <i class="icofont-patient-bed"></i>
                    <h3>Delusional Parasitosis</h3>
                    <p>Delusional parasitosis (DP) leads to an individuals having a persistent belief that they are infested with living or nonliving pathogens such as parasites, insects, or bugs, when no such infestation is present. They usually report
                        tactile hallucinations known as formication, a sensation resembling insects crawling on or under the skin. The condition is observed twice as often in women than men.</p>
                </div>
            </div>
            <div class="col-sm-6 col-lg-4">
                <div class="department-item">
                    <i class="icofont-patient-bed"></i>
                    <h3>Organic Brain Disease</h3>
                    <p>Organic brain syndrome, also known as organic brain disease, organic brain disorder, organic mental syndrome, or organic mental disorder, refers to any syndrome or disorder of mental function whose cause is alleged to be known as organic
                        (physiologic) rather than purely of the mind. These names are older and nearly obsolete general terms from psychiatry.</p>
                </div>
            </div>
            <div class="col-sm-6 col-lg-4">
                <div class="department-item">
                    <i class="icofont-patient-bed"></i>
                    <h3>Delirium</h3>
                    <p>Delirium, also known as acute confusional state, is the organically caused decline from a previous baseline mental functioning that develops over a short period of time, typically hours to days. Delirium is a syndrome encompassing
                        disturbances in attention, consciousness, and cognition. It may also involve other neurological deficits, such as psychomotor disturbances.</p>
                </div>
            </div>
            <div class="col-sm-6 col-lg-4">
                <div class="department-item">
                    <i class="icofont-patient-bed"></i>
                    <h3>Pseudodementia</h3>
                    <p>Pseudodementia or Pseudo Memory Loss (otherwise known as depression-related cognitive dysfunction) is a condition where mental cognition can be temporarily decreased. The term pseudodementia is applied to the range of functional psychiatric
                        conditions such as depression, schizophrenia and hysteria that may mimic organic dementia, but are essentially reversible on treatment. </p>
                </div>
            </div>



        </div>
    </div>
</section>