<div class="page-title-area page-title-four">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="page-title-item">
                <h2>News &amp; Media</h2>
                <ul>
                    <li><a routerLink="#">Home</a></li>
                    <li><i class="icofont-simple-right"></i></li>
                    <li>News &amp; Media</li>
                </ul>
            </div>
        </div>
    </div>
</div>

<section class="blog-area-two pt-100">
    <div class="container">
        <div class="row">
            
            <div class="col-sm-6 col-lg-4 wow fadeInUp" data-wow-delay=".3s">
                <div class="blog-item">
                    <div class="blog-top">
                        <a href="assets/img/news/big/57.jpg" class="popup-youtube"><img src="assets/img/news/57.jpg" alt="News And Media"></a>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-lg-4 wow fadeInUp" data-wow-delay=".3s">
                <div class="blog-item">
                    <div class="blog-top">
                        <a href="assets/img/news/big/56.jpg" class="popup-youtube"><img src="assets/img/news/56.jpg" alt="News And Media"></a>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-lg-4 wow fadeInUp" data-wow-delay=".3s">
                <div class="blog-item">
                    <div class="blog-top">
                        <a href="assets/img/news/big/55.jpg" class="popup-youtube"><img src="assets/img/news/55.jpg" alt="News And Media"></a>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-lg-4 wow fadeInUp" data-wow-delay=".3s">
                <div class="blog-item">
                    <div class="blog-top">
                        <a href="assets/img/news/big/54.jpg" class="popup-youtube"><img src="assets/img/news/54.jpg" alt="News And Media"></a>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-lg-4 wow fadeInUp" data-wow-delay=".3s">
                <div class="blog-item">
                    <div class="blog-top">
                        <a href="assets/img/news/big/53.jpg" class="popup-youtube"><img src="assets/img/news/53.jpg" alt="News And Media"></a>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-lg-4 wow fadeInUp" data-wow-delay=".3s">
                <div class="blog-item">
                    <div class="blog-top">
                        <a href="assets/img/news/big/52.jpg" class="popup-youtube"><img src="assets/img/news/52.jpg" alt="News And Media"></a>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-lg-4 wow fadeInUp" data-wow-delay=".3s">
                <div class="blog-item">
                    <div class="blog-top">
                        <a href="assets/img/news/big/51.jpg" class="popup-youtube"><img src="assets/img/news/51.jpg" alt="News And Media"></a>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-lg-4 wow fadeInUp" data-wow-delay=".3s">
                <div class="blog-item">
                    <div class="blog-top">
                        <a href="assets/img/news/big/50.jpg" class="popup-youtube"><img src="assets/img/news/50.jpg" alt="News And Media"></a>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-lg-4 wow fadeInUp" data-wow-delay=".3s">
                <div class="blog-item">
                    <div class="blog-top">
                        <a href="assets/img/news/big/49.jpg" class="popup-youtube"><img src="assets/img/news/49.jpg" alt="News And Media"></a>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-lg-4 wow fadeInUp" data-wow-delay=".3s">
                <div class="blog-item">
                    <div class="blog-top">
                        <a href="assets/img/news/big/48.jpg" class="popup-youtube"><img src="assets/img/news/48.jpg" alt="News And Media"></a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4 wow fadeInUp" data-wow-delay=".3s">
                <div class="blog-item">
                    <div class="blog-top">
                        <a href="assets/img/news/big/47.jpg" class="popup-youtube"><img src="assets/img/news/47.jpg" alt="News And Media"></a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4 wow fadeInUp" data-wow-delay=".3s">
                <div class="blog-item">
                    <div class="blog-top">
                        <a href="assets/img/news/big/46.jpg" class="popup-youtube"><img src="assets/img/news/46.jpg" alt="News And Media"></a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4 wow fadeInUp" data-wow-delay=".3s">
                <div class="blog-item">
                    <div class="blog-top">
                        <a href="assets/img/news/big/45.jpg" class="popup-youtube"><img src="assets/img/news/45.jpg" alt="News And Media"></a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4 wow fadeInUp" data-wow-delay=".3s">
                <div class="blog-item">
                    <div class="blog-top">
                        <a href="assets/img/news/big/44.jpg" class="popup-youtube"><img src="assets/img/news/44.jpg" alt="News And Media"></a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4 wow fadeInUp" data-wow-delay=".3s">
                <div class="blog-item">
                    <div class="blog-top">
                        <a href="assets/img/news/big/43.jpg" class="popup-youtube"><img src="assets/img/news/43.jpg" alt="News And Media"></a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4 wow fadeInUp" data-wow-delay=".3s">
                <div class="blog-item">
                    <div class="blog-top">
                        <a href="assets/img/news/big/42.jpg" class="popup-youtube"><img src="assets/img/news/42.jpg" alt="News And Media"></a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4 wow fadeInUp" data-wow-delay=".3s">
                <div class="blog-item">
                    <div class="blog-top">
                        <a href="assets/img/news/big/41.jpg" class="popup-youtube"><img src="assets/img/news/41.jpg" alt="News And Media"></a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4 wow fadeInUp" data-wow-delay=".3s">
                <div class="blog-item">
                    <div class="blog-top">
                        <a href="assets/img/news/big/40.jpg" class="popup-youtube"><img src="assets/img/news/40.jpg" alt="News And Media"></a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4 wow fadeInUp" data-wow-delay=".3s">
                <div class="blog-item">
                    <div class="blog-top">
                        <a href="assets/img/news/big/39.jpg" class="popup-youtube"><img src="assets/img/news/39.jpg" alt="News And Media"></a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4 wow fadeInUp" data-wow-delay=".3s">
                <div class="blog-item">
                    <div class="blog-top">
                        <a href="assets/img/news/big/38.jpg" class="popup-youtube"><img src="assets/img/news/38.jpg" alt="News And Media"></a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4 wow fadeInUp" data-wow-delay=".5s">
                <div class="blog-item">
                    <div class="blog-top">
                        <a href="assets/img/news/big/37.jpg" class="popup-youtube"><img src="assets/img/news/37.jpg" alt="News And Media"></a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4 wow fadeInUp" data-wow-delay=".3s">
                <div class="blog-item">
                    <div class="blog-top">
                        <a href="assets/img/news/big/36.jpg" class="popup-youtube"><img src="assets/img/news/36.jpg" alt="News And Media"></a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4 wow fadeInUp" data-wow-delay=".5s">
                <div class="blog-item">
                    <div class="blog-top">
                        <a href="assets/img/news/big/35.jpg" class="popup-youtube"><img src="assets/img/news/35.jpg" alt="News And Media"></a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4 wow fadeInUp" data-wow-delay=".7s">
                <div class="blog-item">
                    <div class="blog-top">
                        <a href="assets/img/news/big/34.jpg" class="popup-youtube"><img src="assets/img/news/34.jpg" alt="News And Media"></a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4 wow fadeInUp" data-wow-delay=".3s">
                <div class="blog-item">
                    <div class="blog-top">
                        <a href="assets/img/news/big/33.jpg" class="popup-youtube"><img src="assets/img/news/33.jpg" alt="News And Media"></a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4 wow fadeInUp" data-wow-delay=".5s">
                <div class="blog-item">
                    <div class="blog-top">
                        <a href="assets/img/news/big/32.jpg" class="popup-youtube"><img src="assets/img/news/32.jpg" alt="News And Media"></a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4 wow fadeInUp" data-wow-delay=".7s">
                <div class="blog-item">
                    <div class="blog-top">
                        <a href="assets/img/news/big/31.jpg" class="popup-youtube"><img src="assets/img/news/31.jpg" alt="News And Media"></a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4 wow fadeInUp" data-wow-delay=".3s">
                <div class="blog-item">
                    <div class="blog-top">
                        <a href="assets/img/news/big/30.jpg" class="popup-youtube"><img src="assets/img/news/30.jpg" alt="News And Media"></a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4 wow fadeInUp" data-wow-delay=".5s">
                <div class="blog-item">
                    <div class="blog-top">
                        <a href="assets/img/news/big/29.jpg" class="popup-youtube"><img src="assets/img/news/29.jpg" alt="News And Media"></a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4 wow fadeInUp" data-wow-delay=".7s">
                <div class="blog-item">
                    <div class="blog-top">
                        <a href="assets/img/news/big/28.jpg" class="popup-youtube"><img src="assets/img/news/28.jpg" alt="News And Media"></a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4 wow fadeInUp" data-wow-delay=".3s">
                <div class="blog-item">
                    <div class="blog-top">
                        <a href="assets/img/news/big/27.jpg" class="popup-youtube"><img src="assets/img/news/27.jpg" alt="News And Media"></a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4 wow fadeInUp" data-wow-delay=".5s">
                <div class="blog-item">
                    <div class="blog-top">
                        <a href="assets/img/news/big/26.jpg" class="popup-youtube"><img src="assets/img/news/26.jpg" alt="News And Media"></a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4 wow fadeInUp" data-wow-delay=".7s">
                <div class="blog-item">
                    <div class="blog-top">
                        <a href="assets/img/news/big/25.jpg" class="popup-youtube"><img src="assets/img/news/25.jpg" alt="News And Media"></a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4 wow fadeInUp" data-wow-delay=".3s">
                <div class="blog-item">
                    <div class="blog-top">
                        <a href="assets/img/news/big/24.jpg" class="popup-youtube"><img src="assets/img/news/24.jpg" alt="News And Media"></a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4 wow fadeInUp" data-wow-delay=".5s">
                <div class="blog-item">
                    <div class="blog-top">
                        <a href="assets/img/news/big/23.jpg" class="popup-youtube"><img src="assets/img/news/23.jpg" alt="News And Media"></a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4 wow fadeInUp" data-wow-delay=".7s">
                <div class="blog-item">
                    <div class="blog-top">
                        <a href="assets/img/news/big/22.jpg" class="popup-youtube"><img src="assets/img/news/22.jpg" alt="News And Media"></a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4 wow fadeInUp" data-wow-delay=".3s">
                <div class="blog-item">
                    <div class="blog-top">
                        <a href="assets/img/news/big/21.jpg" class="popup-youtube"><img src="assets/img/news/21.jpg" alt="News And Media"></a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4 wow fadeInUp" data-wow-delay=".5s">
                <div class="blog-item">
                    <div class="blog-top">
                        <a href="assets/img/news/big/20.jpg" class="popup-youtube"><img src="assets/img/news/20.jpg" alt="News And Media"></a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4 wow fadeInUp" data-wow-delay=".7s">
                <div class="blog-item">
                    <div class="blog-top">
                        <a href="assets/img/news/big/19.jpg" class="popup-youtube"><img src="assets/img/news/19.jpg" alt="News And Media"></a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4 wow fadeInUp" data-wow-delay=".3s">
                <div class="blog-item">
                    <div class="blog-top">
                        <a href="assets/img/news/big/18.jpg" class="popup-youtube"><img src="assets/img/news/18.jpg" alt="News And Media"></a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4 wow fadeInUp" data-wow-delay=".5s">
                <div class="blog-item">
                    <div class="blog-top">
                        <a href="assets/img/news/big/17.jpg" class="popup-youtube"><img src="assets/img/news/17.jpg" alt="News And Media"></a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4 wow fadeInUp" data-wow-delay=".7s">
                <div class="blog-item">
                    <div class="blog-top">
                        <a href="assets/img/news/big/16.jpg" class="popup-youtube"><img src="assets/img/news/16.jpg" alt="News And Media"></a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4 wow fadeInUp" data-wow-delay=".3s">
                <div class="blog-item">
                    <div class="blog-top">
                        <a href="assets/img/news/big/15.jpg" class="popup-youtube"><img src="assets/img/news/15.jpg" alt="News And Media"></a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4 wow fadeInUp" data-wow-delay=".5s">
                <div class="blog-item">
                    <div class="blog-top">
                        <a href="assets/img/news/big/14.jpg" class="popup-youtube"><img src="assets/img/news/14.jpg" alt="News And Media"></a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4 wow fadeInUp" data-wow-delay=".7s">
                <div class="blog-item">
                    <div class="blog-top">
                        <a href="assets/img/news/big/13.jpg" class="popup-youtube"><img src="assets/img/news/13.jpg" alt="News And Media"></a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4 wow fadeInUp" data-wow-delay=".3s">
                <div class="blog-item">
                    <div class="blog-top">
                        <a href="assets/img/news/big/12.jpg" class="popup-youtube"><img src="assets/img/news/12.jpg" alt="News And Media"></a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4 wow fadeInUp" data-wow-delay=".5s">
                <div class="blog-item">
                    <div class="blog-top">
                        <a href="assets/img/news/big/11.jpg" class="popup-youtube"><img src="assets/img/news/11.jpg" alt="News And Media"></a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4 wow fadeInUp" data-wow-delay=".7s">
                <div class="blog-item">
                    <div class="blog-top">
                        <a href="assets/img/news/big/10.jpg" class="popup-youtube"><img src="assets/img/news/10.jpg" alt="News And Media"></a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4 wow fadeInUp" data-wow-delay=".3s">
                <div class="blog-item">
                    <div class="blog-top">
                        <a href="assets/img/news/big/9.jpg" class="popup-youtube"><img src="assets/img/news/9.jpg" alt="News And Media"></a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4 wow fadeInUp" data-wow-delay=".5s">
                <div class="blog-item">
                    <div class="blog-top">
                        <a href="assets/img/news/big/8.jpg" class="popup-youtube"><img src="assets/img/news/8.jpg" alt="News And Media"></a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4 wow fadeInUp" data-wow-delay=".7s">
                <div class="blog-item">
                    <div class="blog-top">
                        <a href="assets/img/news/big/7.jpg" class="popup-youtube"><img src="assets/img/news/7.jpg" alt="News And Media"></a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4 wow fadeInUp" data-wow-delay=".3s">
                <div class="blog-item">
                    <div class="blog-top">
                        <a href="assets/img/news/big/6.jpg" class="popup-youtube"><img src="assets/img/news/6.jpg" alt="News And Media"></a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4 wow fadeInUp" data-wow-delay=".5s">
                <div class="blog-item">
                    <div class="blog-top">
                        <a href="assets/img/news/big/5.jpg" class="popup-youtube"><img src="assets/img/news/5.jpg" alt="News And Media"></a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4 wow fadeInUp" data-wow-delay=".7s">
                <div class="blog-item">
                    <div class="blog-top">
                        <a href="assets/img/news/big/4.jpg" class="popup-youtube"><img src="assets/img/news/4.jpg" alt="News And Media"></a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4 wow fadeInUp" data-wow-delay=".3s">
                <div class="blog-item">
                    <div class="blog-top">
                        <a href="assets/img/news/big/3.jpg" class="popup-youtube"><img src="assets/img/news/3.jpg" alt="News And Media"></a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4 wow fadeInUp" data-wow-delay=".5s">
                <div class="blog-item">
                    <div class="blog-top">
                        <a href="assets/img/news/big/2.jpg" class="popup-youtube"><img src="assets/img/news/2.jpg" alt="News And Media"></a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4 wow fadeInUp" data-wow-delay=".7s">
                <div class="blog-item">
                    <div class="blog-top">
                        <a href="assets/img/news/big/1.jpg" class="popup-youtube"><img src="assets/img/news/1.jpg" alt="News And Media"></a>
                    </div>
                </div>
            </div>

        </div>
    </div>
</section>