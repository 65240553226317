<div class="page-title-area page-title-four">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="page-title-item">
                <h2>Privacy Policy</h2>
                <ul>
                    <li><a routerLink="/">Home</a></li>
                    <li><i class="icofont-simple-right"></i></li>
                    <li>Privacy Policy</li>
                </ul>
            </div>
        </div>
    </div>
</div>

<section class="privacy-area pt-100">
    <div class="container">
        <div class="privacy-item">
            <h2>This Privacy Policy applies to the drpawanbarnwal.com</h2>
            <p>drpawanbarnwal.com recognises the importance of maintaining your privacy. We value your privacy and appreciate your trust in us. This Policy describes how we treat user information we collect on http://www.bookmyseats.in and other offline
                sources. This Privacy Policy applies to current and former visitors to our website and to our online customers. By visiting and/or using our website, you agree to this Privacy Policy.</p>
            <br>
            <p>drpawanbarnwal.com is a property of Neuropsychiatry & Drug De-addiction Centre, an Indian Company registered under the Clinic Establishment Act having its registered office at Opposite First Mark School, Near Firing Range, Bariatu, Ranchi
                (Jharkhand) 834009.</p>
        </div>

        <div class="privacy-item">
            <h2>Information we collect</h2>
            <p><strong>Contact information.</strong> We might collect your name, email, mobile number, phone number, street, city, state, pincode, country and ip address.</p>
            <br>
            <p><strong>Payment and billing information.</strong> We might collect your billing name, billing address and payment method when you buy a ticket. We NEVER collect your credit card number or credit card expiry date or other details pertaining
                to your credit card on our website. Credit card information will be obtained and processed by our online payment partner CC Avenue.</p>
            <br>
            <p><strong>Information you post.</strong> We collect information you post in a public space on our website or on a third-party social media site belonging to drpawanbarnwal.com.</p>
            <br>
            <p><strong>Demographic information.</strong> We may collect demographic information about you, events you like, events you intend to participate in, tickets you buy, or any other information provided by your during the use of our website. We
                might collect this as a part of a survey also.</p>
            <br>
            <p><strong>Other information.</strong> If you use our website, we may collect information about your IP address and the browser you're using. We might look at what site you came from, duration of time spent on our website, pages accessed or what
                site you visit when you leave us. We might also collect the type of mobile device you are using, or the version of the operating system your computer or device is running.</p>
        </div>

        <div class="privacy-item">
            <h2>We collect information in different ways</h2>
            <p><strong>We collect information directly from you.</strong> We collect information directly from you when you register for an event or buy tickets. We also collect information if you post a comment on our websites or ask us a question through
                phone or email.</p>
            <br>
            <p><strong>We collect information from you passively.</strong> We use tracking tools like Google Analytics, Google Webmaster, browser cookies and web beacons for collecting information about your usage of our website.</p>
            <br>
            <p><strong>We get information about you from third parties.</strong> For example, if you use an integrated social media feature on our websites. The third-party social media site will give us certain information about you. This could include
                your name and email address.</p>
            <br>
            <p><strong>Demographic information.</strong> We may collect demographic information about you, events you like, events you intend to participate in, tickets you buy, or any other information provided by your during the use of our website.</p>
        </div>

        <div class="privacy-item">
            <h2>Use of your personal information</h2>
            <p><strong>We use information to contact you.</strong> We might use the information you provide to contact you for confirmation of a purchase on our website or for other promotional purposes.</p>
            <br>
            <p><strong>We use information to respond to your requests or questions.</strong> We might use your information to confirm your registration for an event or contest.</p>
            <br>
            <p><strong>We use information to improve our products and services.</strong> We might use your information to customize your experience with us. This could include displaying content based upon your preferences.</p>
            <br>
            <p><strong>We use information to look at site trends and customer interests.</strong> We may use your information to make our website and products better. We may combine information we get from you with information about you we get from third
                parties.
            </p>
            <br>
            <p><strong>We use information for security purposes.</strong> We may use information to protect our company, our customers, or our websites.</p>
            <br>
            <p><strong>We use information for marketing purposes.</strong> We might send you information about special promotions or offers. We might also tell you about new features or products. These might be our own offers or products, or third-party
                offers or products we think you might find interesting. Or, for example, if you buy tickets from us we'll enroll you in our newsletter.</p>
            <br>
            <p><strong>We use information to send you transactional communications.</strong> We might send you emails or SMS about your account or a ticket purchase.</p>
            <br>
            <p>We use information as otherwise permitted by law.</p>
        </div>

        <div class="privacy-item">
            <h2>Sharing of information with third-parties</h2>
            <p><strong>We will share information with third parties who perform services on our behalf.</strong> We share information with vendors who help us manage our online registration process or payment processors or transactional message processors.
                Some vendors may be located outside of India.</p>
            <br>
            <p><strong>We will share information with the event organizers.</strong> We share your information with event organizers and other parties responsible for fulfilling the purchase obligation. The event organizers and other parties may use the
                information we give them as described in their privacy policies. </p>
            <br>
            <p><strong>We will share information with our business partners.</strong> This includes a third party who provide or sponsor an event, or who operates a venue where we hold events. Our partners use the information we give them as described in
                their privacy policies.</p>
            <br>
            <p><strong>We may share information if we think we have to in order to comply with the law or to protect ourselves.</strong> We will share information to respond to a court order or subpoena. We may also share it if a government agency or investigatory
                body requests. Or, we might also share information when we are investigating potential fraud.</p>
            <br>
            <p><strong>We may share information with any successor to all or part of our business.</strong> For example, if part of our business is sold we may give our customer list as part of that transaction.</p>
            <br>
            <p><strong>We may share your information for reasons not described in this policy.</strong> We will tell you before we do this.</p>

        </div>

        <div class="privacy-item">
            <h2>Third party sites</h2>
            <p>If you click on one of the links to third party websites, you may be taken to websites we do not control. This policy does not apply to the privacy practices of those websites. Read the privacy policy of other websites carefully. We are not
                responsible for these third party sites.</p>
        </div>

        <div class="privacy-item">
            <h2>Updates to this policy</h2>
            <p>This Privacy Policy was last updated on 12.12.2014. From time to time we may change our privacy practices. We will notify you of any material changes to this policy as required by law. We will also post an updated copy on our website. Please
                check our site periodically for updates.</p>
        </div>

        <div class="privacy-item">
            <h2>Jurisdiction</h2>
            <p>If you choose to visit the website, your visit and any dispute over privacy is subject to this Policy and the website's terms of use. In addition to the foregoing, any disputes arising under this Policy shall be governed by the laws of India.</p>
        </div>
    </div>
</section>