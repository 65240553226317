<div class="page-title-area page-title-four">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="page-title-item-two">
                <h3>Dr. Pawan Kr Barnwal</h3>
                <p><strong>MD (Psychiatry), BHU, FIPS<br>Former chief registrar, PGI (Chandigarh)</strong></p>
            </div>
        </div>
    </div>
</div>

<div class="doctor-details-area mt-50 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-5">
                <div class="doctor-details-item doctor-details-left">
                    <img src="assets/img/doctor/3.jpg" alt="Doctor">

                    <div class="doctor-details-contact">
                        <h3>Contact info</h3>
                        <ul>
                            <li><a href="tel:+916512540218"><i class="icofont-ui-call"></i> +91 651 254 0218</a></li>
                            <li><a href="https://wa.me/917765912428" target="_blank"><i class="icofont-whatsapp"></i> +91 77659 12428</a></li>
                            <li><i class="icofont-ui-message"></i> dr_pawanbarnwal&#64;yahoo.com</li>
                            <li><a href="https://www.google.com/maps/dir//dr+pawan+barnwal/data=!4m6!4m5!1m1!4e2!1m2!1m1!1s0x39f4e3fa0e059381:0xa4024a9f678bf4bf?sa=X&ved=2ahUKEwic2f_hle3tAhWKcn0KHcLZAgQQ9RcwC3oECBYQBQ" target="_blank"><i class="icofont-location-pin"></i> Opposite First Mark School <br>Near Firing Range, Bariatu, Ranchi (Jharkhand) 834009</a></li>
                        </ul>
                    </div>

                    <div class="doctor-details-work">
                        <h3>Clinic Timings</h3>
                        <div class="appointment-item-two-right">
                            <div class="appointment-item-content">
                                <div class="content-one">
                                    <ul>
                                        <li><i class="icofont-clock-time"></i> Monday</li>
                                        <li><i class="icofont-clock-time"></i> Tuesday</li>
                                        <li><i class="icofont-clock-time"></i> Wednesday</li>
                                        <li><i class="icofont-clock-time"></i> Thursday</li>
                                        <li><i class="icofont-clock-time"></i> Friday</li>
                                        <li><i class="icofont-clock-time"></i> Saturday</li>
                                        <li style="color:#0046c0;"><i class="icofont-clock-time"></i> Sunday</li>
                                    </ul>
                                </div>
                                <div class="content-two">
                                    <ul>
                                        <li>10:00 AM - 4:00 PM</li>
                                        <li>10:00 AM - 4:00 PM</li>
                                        <li>10:00 AM - 4:00 PM</li>
                                        <li>10:00 AM - 4:00 PM</li>
                                        <li>10:00 AM - 4:00 PM</li>
                                        <li>10:00 AM - 4:00 PM</li>
                                        <li style="color:#0046c0;">Closed</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-7">
                <div class="doctor-details-item">
                    <div class="doctor-details-right">
                        <div class="doctor-details-biography">
                            <h3>Biography</h3>
                            <h5>"Patient care is his topmost priority"</h5>
                            <p>Dr. Pawan Kumar Barnwal is a much talked about and eminent Neuropsychiatrist and Behavior Science Specialist in Ranchi, Jharkhand (India). He has achieved a special space for himself in the field of Neuropsychiatry and allied
                                branches like Drug De-addiction and sexual problems.</p><br>
                            <p>He has made treatment of complex diseases associated with Neuropsychiatry and Drug De-addiction very simple for which people use to go to other places.</p>
                            <p>Treatment of challenging Neuropsychiatric disease is Dr. Barnwal's passion. He has mastered the cure for all types of addiction. He says that any type of addication is curable. Furthermore, he establishes the fact that there
                                isn't any Neuropsychiatric illness which cannot be cured given that timely treatment is opted from the right place.</p>
                        </div>

                        <div class="doctor-details-biography">
                            <h3>Education &amp; Experience</h3>
                            <ul>
                                <li><i class="icofont-graduate-alt"></i> Former Senior Resident in Psychiatry &amp; Drug De-addiction from PGIMER, Chandigarh</li>
                                <li><i class="icofont-graduate-alt"></i> UNODC (United Nations Office on Drugs and Crime) Master Trained</li>
                                <li><i class="icofont-graduate-alt"></i> MD in Psychiatry from IMS BHU, Varanasi</li>
                                <li><i class="icofont-graduate-alt"></i> MBBS degree from RIMS (formaly Rajendra Medical College &amp; Hospital), Ranchi</li>
                                <li><i class="icofont-graduate-alt"></i> Higher Secondary Certificate from DSM College, Jhajha</li>
                                <li><i class="icofont-graduate-alt"></i> Secondary Certificate from Eastern Railway High School, Jhajha (Bihar)</li>
                            </ul>
                        </div>

                        <div class="doctor-details-biography">
                            <h3>Publications in Index Journals</h3>
                            <ul>
                                <ul style="list-style: none;"><i class="icofont-book-alt"></i> <strong>International Journals:</strong>
                                    <li>1. The Lancet</li>
                                    <li>2. British Journal of Psychiatry</li>
                                    <li>3. Asian Journal of Psychiatry</li>
                                </ul>
                                <br>
                                <ul style="list-style: none;"><i class="icofont-book-alt"></i> <strong>National Journals:</strong>
                                    <li>1. Indian Journal of Psychiatry</li>
                                </ul>
                            </ul>
                        </div>
                        <div class="doctor-details-biography">
                            <h3>Research</h3>
                            <i class="icofont-book-alt"></i> <strong>A study of intracranial (structural) changes in generalized Anxiety Disorder</strong>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="appointment-area-three">
    <div class="container-fluid p-0">
        <div class="row m-0">
            <div class="col-lg-7">
                <div class="appointment-item appointment-item-two">
                    <div class="appointment-shape">
                        <img src="assets/img/appointment/3.png" alt="Shape">
                    </div>

                    <h2>Awards & Recognitions</h2>
                    <ul style="list-style: none;">
                        <li><i class="icofont-award"></i> Architect of Jharkhand on September 27, 2016 (Awarded by Union Agriculture Minister Shri Radha Mohan Singh (Government of India))</li>
                        <li><i class="icofont-award"></i> Doctors Sammaan on August 8, 2018 (Awarded by State Health Minister Shri Ramchandra Chandravanshi (Government of Jharkhand) and Swami Ishwarananda Giri, General Secretary, Yogoda Satsanga Society of
                            India) </li>
                        <li><i class="icofont-award"></i> Corona Warrior on December 27, 2020 (Facilitated by Member of Parliament Shri Sanjay Seth for distinguished work in Psychiatry)</li>
                        <li><i class="icofont-award"></i> Certificate of honour on January 8, 2009 (Awarded by Indian Psychiatric society at 61st Annual National Conference in Agra)</li>
                    </ul>

                    <h2>Major Conferences</h2>
                    <ul style="list-style: none;">
                        <h6><b>International:</b></h6>
                        <li><i class="icofont-users-alt-2"></i> Dubai (UAE)</li>
                        <li><i class="icofont-users-alt-2"></i> Budapest (Hungry)</li>
                        <li><i class="icofont-users-alt-2"></i> Sophia (Bulgaria)</li>
                        <li><i class="icofont-users-alt-2"></i> Cairo (Egypt)</li>
                        <br>
                        <h6><b>National:</b></h6>
                        <li><i class="icofont-users-alt-2"></i> ANCIPS</li>
                        <li><i class="icofont-users-alt-2"></i> ANCIAPP</li>
                        <li><i class="icofont-users-alt-2"></i> ANCIABP</li>
                        <li><i class="icofont-users-alt-2"></i> IPS National Mid Term CME</li>
                        <br>
                        <h6><b>Zonal:</b></h6>
                        <li><i class="icofont-users-alt-2"></i> CEZIPS</li>
                        <li><i class="icofont-users-alt-2"></i> IPS-NZ</li>
                        <li><i class="icofont-users-alt-2"></i> CIPCON</li>
                        <br>
                        <h6><b>State:</b></h6>
                        <li><i class="icofont-users-alt-2"></i> JASCON</li>
                        <li><i class="icofont-users-alt-2"></i> JIMACON</li>
                        <li><i class="icofont-users-alt-2"></i> PG CME East Zone</li>
                        <li><i class="icofont-users-alt-2"></i> PG CME North India</li>
                        <br>
                        <h6><b>Others:</b></h6>
                        <li><i class="icofont-users-alt-2"></i> American Psychiatric Association - Indian Summit, Mumbai</li>
                        <li><i class="icofont-users-alt-2"></i> ANCPD Kokilaben Dhirubhai Ambani Hospital &amp; Research Institute, Mumbai</li>
                        <li><i class="icofont-users-alt-2"></i> CNS Summit, Goa and Kolkata</li>
                        <li><i class="icofont-users-alt-2"></i> Neurocon BHU, Varanasi</li>
                        <li><i class="icofont-users-alt-2"></i> RCIP, Agra</li>
                        <li><i class="icofont-users-alt-2"></i> NMO, Amritsar</li>

                    </ul>
                </div>
            </div>

            <div class="col-lg-5 pr-0">
                <div class="speciality-item speciality-right speciality-right-two speciality-right-three">
                    <img src="assets/img/doctor/4.jpg" alt="Doctor">
                </div>
            </div>
        </div>
    </div>
</div>
<section class="blog-area-two">
    <div class="container">
        <div class="row">
            <div class="col-sm-6 col-lg-4 wow fadeInUp" data-wow-delay=".3s">
                <div class="blog-item">
                    <div class="blog-top">
                        <a href="assets/img/doctor/award-big-1.jpg" class="popup-youtube"><img src="assets/img/doctor/award-thumb-1.jpg" alt="Award 1"></a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4 wow fadeInUp" data-wow-delay=".5s">
                <div class="blog-item">
                    <div class="blog-top">
                        <a href="assets/img/doctor/award-big-2.jpg" class="popup-youtube"><img src="assets/img/doctor/award-thumb-2.jpg" alt="Award 2"></a>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-lg-4 wow fadeInUp" data-wow-delay=".5s">
                <div class="blog-item">
                    <div class="blog-top">
                        <a href="assets/img/doctor/award-big-9.jpg" class="popup-youtube"><img src="assets/img/doctor/award-thumb-9.jpg" alt="Award 2"></a>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-lg-4 wow fadeInUp" data-wow-delay=".5s">
                <div class="blog-item">
                    <div class="blog-top">
                        <a href="assets/img/doctor/award-big-3.jpg" class="popup-youtube"><img src="assets/img/doctor/award-thumb-3.jpg" alt="Award 3"></a>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-lg-4 wow fadeInUp" data-wow-delay=".5s">
                <div class="blog-item">
                    <div class="blog-top">
                        <a href="assets/img/doctor/award-big-4.jpg" class="popup-youtube"><img src="assets/img/doctor/award-thumb-4.jpg" alt="Award 4"></a>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-lg-4 wow fadeInUp" data-wow-delay=".5s">
                <div class="blog-item">
                    <div class="blog-top">
                        <a href="assets/img/doctor/award-big-5.jpg" class="popup-youtube"><img src="assets/img/doctor/award-thumb-5.jpg" alt="Award 5"></a>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-lg-4 wow fadeInUp" data-wow-delay=".5s">
                <div class="blog-item">
                    <div class="blog-top">
                        <a href="assets/img/doctor/award-big-6.jpg" class="popup-youtube"><img src="assets/img/doctor/award-thumb-6.jpg" alt="Award 6"></a>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-lg-4 wow fadeInUp" data-wow-delay=".5s">
                <div class="blog-item">
                    <div class="blog-top">
                        <a href="assets/img/doctor/award-big-7.jpg" class="popup-youtube"><img src="assets/img/doctor/award-thumb-7.jpg" alt="Award 7"></a>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-lg-4 wow fadeInUp" data-wow-delay=".5s">
                <div class="blog-item">
                    <div class="blog-top">
                        <a href="assets/img/doctor/award-big-8.jpg" class="popup-youtube"><img src="assets/img/doctor/award-thumb-8.jpg" alt="Award 8"></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>