<div class="page-title-area page-title-five">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="page-title-item">
                <h2>Contact Us</h2>
                <ul>
                    <li><a routerLink="/">Home</a></li>
                    <li><i class="icofont-simple-right"></i></li>
                    <li>Contact us</li>
                </ul>
            </div>
        </div>
    </div>
</div>

<div class="location-area">
    <div class="container">
        <div class="row location-wrap">
            <div class="col-sm-6 col-lg-4">
                <div class="location-item">
                    <i class="icofont-location-pin"></i>
                    <h3>Location</h3>
                    <p><a href="https://www.google.com/maps/dir//dr+pawan+barnwal/data=!4m6!4m5!1m1!4e2!1m2!1m1!1s0x39f4e3fa0e059381:0xa4024a9f678bf4bf?sa=X&ved=2ahUKEwic2f_hle3tAhWKcn0KHcLZAgQQ9RcwC3oECBYQBQ" target="_blank">Opposite First Mark School, Near Firing Range, Bariatu, Ranchi (JH) 834009</a></p>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4">
                <div class="location-item">
                    <i class="icofont-ui-message"></i>
                    <h3>Email</h3>
                    <ul>
                        <li>info&#64;drpawanbarnwal.com</li>
                    </ul>
                </div>
            </div>

            <div class="col-sm-6 offset-sm-3 offset-lg-0 col-lg-4">
                <div class="location-item">
                    <i class="icofont-ui-call"></i>
                    <h3>Phone</h3>
                    <ul>
                        <li><a href="tel:06512540218">+91 651 254 0218</a></li>
                        <li><a href="https://wa.me/917765912428">+91 77659 12428</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="contact-map-area">
    <div id="map">
        <iframe width="100%" height="100%" frameborder="0" style="border:0" src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJgZMFDvrj9DkRv_SLZ59KAqQ&key=AIzaSyBSolILrlyz8LLcS61OPZ0WmM-w-Eyw5w8" allowfullscreen></iframe>
    </div>
</div>