<div class="page-title-area page-title-four">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="page-title-item">
                <h2>Refund Policy</h2>
                <ul>
                    <li><a routerLink="/">Home</a></li>
                    <li><i class="icofont-simple-right"></i></li>
                    <li>Refund Policy</li>
                </ul>
            </div>
        </div>
    </div>
</div>

<section class="privacy-area pt-100">
    <div class="container">
        <div class="privacy-item">
            <h2>This Refund Policy applies to the drpawanbarnwal.com (if applicable)</h2>
            <p>Once your cancellation request is received and inspected, we will send you an email to notify you that we have received your cancellation request. We will also notify you of the approval or rejection of your refund.
               If you are approved, then your refund will be processed, and a credit will automatically be applied to your credit card or original method of payment, within a certain amount of days.</p>
        </div>

        <div class="privacy-item">
            <h2>Late or missing refunds (if applicable)</h2>
            <p>If you haven’t received a refund yet, first check your bank account again. Then contact your credit card company, it may take some time before your refund is officially posted.
                Next contact your bank. There is often some processing time before a refund is posted.
                
                If you’ve done all of this and you still have not received your refund yet, please contact us at contact info&#64;drpawanbarnwal.com</p>
        </div>
    </div>
</section>