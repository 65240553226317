import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeOneComponent } from './components/pages/home-one/home-one.component';
import { ErrorComponent } from './components/pages/error/error.component';
import { TermsComponent } from './components/pages/terms/terms.component';
import { PrivacyPolicyComponent } from './components/pages/privacy-policy/privacy-policy.component';
import { AppointmentComponent } from './components/pages/appointment/appointment.component';
import { DoctorDetailsComponent } from './components/pages/doctor-details/doctor-details.component';
import { ContactComponent } from './components/pages/contact/contact.component';
import { VideoComponent } from './components/pages/video-gallery/video.component';
import { PhotoComponent } from './components/pages/photo-gallery/photo.component';
import { NewsComponent } from './components/pages/news-media/news.component';
import { DiseasesAdultComponent } from './components/pages/diseases-adult/diseases-adult.component';
import { DiseasesChildComponent } from './components/pages/diseases-child/diseases-child.component';
import { DiseasesGeriatricComponent } from './components/pages/diseases-geriatric/diseases-geriatric.component';
import { DiseasesWomenComponent } from './components/pages/diseases-women/diseases-women.component';
import { DiseasesSocialComponent } from './components/pages/diseases-social/diseases-social.component';
import { DiseasesDrugComponent } from './components/pages/diseases-drug/diseases-drug.component';
import { DiseasesSexualComponent } from './components/pages/diseases-sexual/diseases-sexual.component';
import { RefundPolicyComponent } from './components/pages/refund-policy/refund-policy.component';

const routes: Routes = [
    {path: '', component: HomeOneComponent},
    {path: 'appointment', component: AppointmentComponent},
    {path: 'privacy-policy', component: PrivacyPolicyComponent},
    {path: 'terms', component: TermsComponent},
    {path: 'error', component: ErrorComponent},
    {path: 'doctor-details', component: DoctorDetailsComponent},
    {path: 'contact', component: ContactComponent},
    {path: 'video', component: VideoComponent},
    {path: 'photo', component: PhotoComponent},
    {path: 'news', component: NewsComponent},
    {path: 'diseases-adult', component: DiseasesAdultComponent},
    {path: 'diseases-child', component: DiseasesChildComponent},
    {path: 'diseases-geriatric', component: DiseasesGeriatricComponent},
    {path: 'diseases-women', component: DiseasesWomenComponent},
    {path: 'diseases-social', component: DiseasesSocialComponent},
    {path: 'diseases-drug', component: DiseasesDrugComponent},
    {path: 'diseases-sexual', component: DiseasesSexualComponent},
    {path: 'refund-policy', component: RefundPolicyComponent},
    // Here add new pages component

    {path: '**', component: ErrorComponent} // This line will remain down from the whole pages component list
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule]
})
export class AppRoutingModule { }