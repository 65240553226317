<footer class="pb-70">
    <div class="newsletter-area">
        <div class="container">
            <div class="row newsletter-wrap">
                <div class="col-lg-6">
                    <div class="newsletter-item">
                        <h2>The Cure Is Here...</h2>
                    </div>
                </div>

                <div class="col-lg-6">
                    <div class="newsletter-item">
                        <div class="newsletter-form">
                            <form class="newsletter-form">
                                <a  routerLink="/appointment" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="btn newsletter-btn" type="number">Get Appointment Now</a>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container">
        <div class="row">
            <div class="col-sm-6 col-lg-4">
                <div class="footer-item">
                    <div class="footer-contact">
                        <h3>Contact Us</h3>
                        <ul>
                            <li>
                                <i class="icofont-ui-message" style="top:0px!important;"></i>
                                <a href="mailto:info@drpawanbarnwal.com">info&#64;drpawanbarnwal.com</a>
                            </li>
                            <li>
                                <i class="icofont-stock-mobile"></i>
                                <a href="tel:+916512540218">Call: +91 651 254 0218</a>
                                <a href="tel:+917765912428">Call: +91 77659 12428</a>
                                <a href="https://wa.me/917765912428" target="_blank">WhatsApp: +91 77659 12428</a>
                            </li>
                            <li>
                                <i class="icofont-location-pin"></i> <a href="https://www.google.com/maps/dir//dr+pawan+barnwal/data=!4m6!4m5!1m1!4e2!1m2!1m1!1s0x39f4e3fa0e059381:0xa4024a9f678bf4bf?sa=X&ved=2ahUKEwic2f_hle3tAhWKcn0KHcLZAgQQ9RcwC3oECBYQBQ"
                                    target="_blank">Opposite First Mark School, Near Firing Range, Bariatu, Ranchi (Jharkhand) 834009</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-lg-2">
                <div class="footer-item">
                    <div class="footer-quick">
                        <h3>Quick Links</h3>
                        <ul>
                            <li><a routerLink="/doctor-details"><i class="icofont-plus-circle"></i> About Dr. Pawan Kumar Barnwal</a></li>
                            <li><a routerLink="/news"><i class="icofont-plus-circle"></i> News & Media</a></li>
                            <li><a routerLink="/photo"><i class="icofont-plus-circle"></i> Clinic Interior</a></li>
                            <li><a routerLink="/video"><i class="icofont-plus-circle"></i> Videos</a></li>
                            <li><a routerLink="/contact"><i class="icofont-plus-circle"></i> Contact us</a></li>
                            <li><a routerLink="/terms"><i class="icofont-plus-circle"></i> Terms</a></li>
                            <li><a routerLink="/privacy-policy"><i class="icofont-plus-circle"></i> Privacy Policy</a></li>
                            <li><a routerLink="/refund-policy"><i class="icofont-plus-circle"></i> Refund Policy</a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-lg-3">
                <div class="footer-item">
                    <div class="footer-quick">
                        <h3>Disease Information</h3>
                        <ul>
                            <li><a routerLink="/diseases-adult"><i class="icofont-plus-circle"></i> Adult Psychiatry</a></li>
                            <li><a routerLink="/diseases-child"><i class="icofont-plus-circle"></i> Child &amp; Adolescent Psychiatry</a></li>
                            <li><a routerLink="/diseases-geriatric"><i class="icofont-plus-circle"></i> Geriatric (Old Age) Psychiatry</a></li>
                            <li><a routerLink="/diseases-women"><i class="icofont-plus-circle"></i> Woman Psychiatry</a></li>
                            <li><a routerLink="/diseases-social"><i class="icofont-plus-circle"></i> Social, Occupational &amp; Family Psychiatry</a></li>
                            <li><a routerLink="/diseases-drug"><i class="icofont-plus-circle"></i> Substance Abuse &amp; Addiction</a></li>
                            <li><a routerLink="/diseases-sexual"><i class="icofont-plus-circle"></i> Sexual Problems</a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-sm-6 col-lg-3">
                <div class="footer-item">
                    <div class="footer-quick">
                        <h3>Clinic Timings</h3>
                        <ul style="color: #fff;">
                            <li><i class="icofont-clock-time"></i> Mon: 10:00 AM - 4:00 PM</li>
                            <li><i class="icofont-clock-time"></i> Tue: 10:00 AM - 4:00 PM</li>
                            <li><i class="icofont-clock-time"></i> Wed: 10:00 AM - 4:00 PM</li>
                            <li><i class="icofont-clock-time"></i> Thurs: 10:00 AM - 4:00 PM</li>
                            <li><i class="icofont-clock-time"></i> Fri: 10:00 AM - 4:00 PM</li>
                            <li><i class="icofont-clock-time"></i> Sat: 10:00 AM - 4:00 PM</li>
                            <li style="color: #ffde21;"><i class="icofont-clock-time"></i> Sun: Closed</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>

<div class="copyright-area">
    <div class="container">
        <div class="copyright-item">
            <p>© 2020 Dr. Pawan Kumar Barnwal. Powered by <a href="https://dryftdynamics.com/" target="_blank">Dryft Dynamics P Limited</a></p>
        </div>
    </div>
</div>