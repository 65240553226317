<div class="page-title-area page-title-two">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="page-title-item">
                <h2>Get Your Appointment</h2>
                <ul>
                    <li><a routerLink="/">Home</a></li>
                    <li><i class="icofont-simple-right"></i></li>
                    <li>Appointment</li>
                </ul>
            </div>
        </div>
    </div>
</div>

<section class="appointment-area-two">
    <div class="container">
        <div class="row align-items-center appointment-wrap-two">
            <div class="col-lg-7 wow fadeInUp" data-wow-delay=".3s">
                <div class="appointment-item appointment-item-two">
                    <div class="appointment-shape">
                        <img src="assets/img/appointment/3.png" alt="Shape">
                    </div>
                    <h2 style="color: red;">Note: Online appointment and payment is accepted through this official website only.</h2><br>
                    <h2>Procedure of Booking Appointment:</h2>
                    <ul>
                        <li>Visit the section 'Appointments' from website menu or end of each page.</li>
                        <li>Read the important terms and conditions carefully before proceeding.</li>
                        <li>Click on the Book Appointment Button.</li>
                        <li>Fill out the details carefully and correctly.</li>
                        <li>Pay the fee online and save the transaction ID for reference.</li>
                        <li>Kindly present a <b>valid ID proof</b> of the patient before consultation as per <b>Government's Rule.</b></li>
                        <li>It is mandatory to show your <b>transaction details</b> at the front desk in case of clinic visit.</li>
                    </ul>
                    <br>
                    <h2>Important Terms & Conditions:</h2>
                    <ul>
                        <li><strong>No consultation or clinic visit on Sunday.</strong></li>
                        <li>There is no <b>'discount'</b> applicable on the mentioned fee.</li>
                        <li>Fees paid once will <b>not be</b> refunded in any case.</li>
                        <li>Fees paid once will <b>not be</b> adjusted or rescheduled at any cost.</li>
                        <li>Fee payment should be done minimum <b>1 day</b> before the date of appointment.</li>
                    </ul>
                    <br>
                    <div class="row">
                        <!---<div class="col-md-12" style="margin-bottom:10px;">
                            <div class="newsletter-item">
                                <div class="newsletter-form">
                                    <form class="newsletter-form">
                                        <h5 style="color: red;"><strong>Important Notice: Due to the non-availibity of the Doctor, the clinic will not be open on Thursday that is 15th June, 2023. Clinic will be open on Friday that is 16th June, 2023. Kindly plan your bookings and visits accordingly.</strong></h5>
                                        <a href="https://rzp.io/l/vGY90Siix" class="btn newsletter-btn-2" target="_blank" >Clinic Visit Old Booking System</a>
                                        <br><p>(Kindly note that the old booking system will be phased out shortly.)</p>
                                    </form>
                                </div>
                            </div>
                        </div>--->
                        <div class="col-md-12" style="margin-bottom:10px;">
                            <div class="newsletter-item">
                                <div class="newsletter-form">
                                    <form class="newsletter-form">
                                        <a href="https://appointment.drpawanbarnwal.com" class="btn newsletter-btn-2" target="_blank" >Clinic Visit Appointment Booking</a>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br>
                    <h5><strong>NOTE:</strong> After successful payment to confirm your appointment kindly, <br>Call: <a href="tel:+916512540218">+91 651 254 0218</a> <br>Call/SMS: <a href="tel:+91 77659 12428">+91 776 591 2428</a> <br>WhatsApp: <a href="https://wa.me/917765912428">+91 776 591 2428</a><br></h5>
                </div>
            </div>

            <div class="col-lg-5 wow fadeInUp" data-wow-delay=".5s">
                <div class="appointment-item-two-right">
                    <div class="appointment-item-content">
                        <h2>Clinic Timings</h2>
                        <div class="content-one">
                            <ul>
                                <li>Monday</li>
                                <li>Tuesday</li>
                                <li>Wednesday</li>
                                <li>Thursday</li>
                                <li>Friday</li>
                                <li>Saturday</li>
                                <li><strong>Sunday</strong></li>
                            </ul>
                        </div>
                        <div class="content-two">
                            <ul>
                                <li>10 AM - 4 PM</li>
                                <li>10 AM - 4 PM</li>
                                <li>10 AM - 4 PM</li>
                                <li>10 AM - 4 PM</li>
                                <li>10 AM - 4 PM</li>
                                <li>10 AM - 4 PM</li>
                                <li><strong>CLOSED</strong></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>