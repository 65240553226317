<div class="page-title-area page-title-four">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="page-title-item">
                <h2>Clinic Interior</h2>
                <ul>
                    <li><a routerLink="#">Home</a></li>
                    <li><i class="icofont-simple-right"></i></li>
                    <li>Gallery</li>
                    <li><i class="icofont-simple-right"></i></li>
                    <li>Clinic Interior</li>
                </ul>
            </div>
        </div>
    </div>
</div>

<section class="blog-area-two pt-100">
    <div class="container">
        <div class="row">
            <div class="col-sm-6 col-lg-4 wow fadeInUp" data-wow-delay=".3s">
                <div class="blog-item">
                    <div class="blog-top">
                        <a href="assets/img/gallery/big/1.jpg" class="popup-youtube"><img src="assets/img/gallery/1.jpg" alt="Gallery"></a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4 wow fadeInUp" data-wow-delay=".5s">
                <div class="blog-item">
                    <div class="blog-top">
                        <a href="assets/img/gallery/big/2.jpg" class="popup-youtube"><img src="assets/img/gallery/2.jpg" alt="Gallery"></a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4 wow fadeInUp" data-wow-delay=".7s">
                <div class="blog-item">
                    <div class="blog-top">
                        <a href="assets/img/gallery/big/3.jpg" class="popup-youtube"><img src="assets/img/gallery/3.jpg" alt="Gallery"></a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4 wow fadeInUp" data-wow-delay=".3s">
                <div class="blog-item">
                    <div class="blog-top">
                        <a href="assets/img/gallery/big/4.jpg" class="popup-youtube"><img src="assets/img/gallery/4.jpg" alt="Gallery"></a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4 wow fadeInUp" data-wow-delay=".5s">
                <div class="blog-item">
                    <div class="blog-top">
                        <a href="assets/img/gallery/big/5.jpg" class="popup-youtube"><img src="assets/img/gallery/5.jpg" alt="Gallery"></a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4 wow fadeInUp" data-wow-delay=".7s">
                <div class="blog-item">
                    <div class="blog-top">
                        <a href="assets/img/gallery/big/6.jpg" class="popup-youtube"><img src="assets/img/gallery/6.jpg" alt="Gallery"></a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4 wow fadeInUp" data-wow-delay=".3s">
                <div class="blog-item">
                    <div class="blog-top">
                        <a href="assets/img/gallery/big/7.jpg" class="popup-youtube"><img src="assets/img/gallery/7.jpg" alt="Gallery"></a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4 wow fadeInUp" data-wow-delay=".5s">
                <div class="blog-item">
                    <div class="blog-top">
                        <a href="assets/img/gallery/big/8.jpg" class="popup-youtube"><img src="assets/img/gallery/8.jpg" alt="Gallery"></a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4 wow fadeInUp" data-wow-delay=".7s">
                <div class="blog-item">
                    <div class="blog-top">
                        <a href="assets/img/gallery/big/9.jpg" class="popup-youtube"><img src="assets/img/gallery/9.jpg" alt="Gallery"></a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4 wow fadeInUp" data-wow-delay=".3s">
                <div class="blog-item">
                    <div class="blog-top">
                        <a href="assets/img/gallery/big/10.jpg" class="popup-youtube"><img src="assets/img/gallery/10.jpg" alt="Gallery"></a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4 wow fadeInUp" data-wow-delay=".5s">
                <div class="blog-item">
                    <div class="blog-top">
                        <a href="assets/img/gallery/big/11.jpg" class="popup-youtube"><img src="assets/img/gallery/11.jpg" alt="Gallery"></a>
                    </div>
                </div>
            </div>

            <div class="col-sm-6 col-lg-4 wow fadeInUp" data-wow-delay=".7s">
                <div class="blog-item">
                    <div class="blog-top">
                        <a href="assets/img/gallery/big/12.jpg" class="popup-youtube"><img src="assets/img/gallery/12.jpg" alt="Gallery"></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>